import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty, get } from "lodash-es";
import { Link } from "react-router-dom";
import cx from "classnames";

import { getNews, getArticles } from "../../api/index";
import { setNews, setArticles } from "../../Redux/Reducers/news";

import styles from "./Widgets.module.css";

export const Widgets = (props) => {
  const { news, setNews, setArticles } = props;
  const { articles } = news;

  useEffect(() => {
    if (isEmpty(news.news)) {
      getNews().then((res) => {
        if (res.status === 200) {
          setNews(res.data.payload);
        }
      });
    }

    if (isEmpty(articles)) {
      getArticles().then((res) => {
        if (res.status === 200) {
          setArticles(res.data.payload);
        }
      });
    }
    const twttr = window.twttr
    if(!isEmpty(twttr)){
      twttr.widgets.load();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.projects}>
        <div className={styles.imgContainer}>
          <div className={styles.heading}>News Releases</div>
          <div className={styles.widgetInfo}>
            {isEmpty(get(news, "news", [])) ? (
              <div className={cx(styles.label, styles.noUpdates)}>
                No New Updates
              </div>
            ) : (
              news.news.map((item, index) => {
                if (index > 2) return null;
                else if (item.fileUrl != null && item.fileUrl !== "null") {
                return (
                  <div className={styles.info}>
                     <div className={styles.label}><a href={`https://backend.heliosx.net${item.fileUrl.slice(1)}`} target="_blank">{item.heading}</a></div>
                    <div className={styles.subHeading}>
                      {new Date(item.date).toDateString().slice(4)}
                    </div>
                  </div>
                )}
                else {
                  return (
                    <div className={styles.info}>
                       <div className={styles.label}>{item.heading}</div>
                      <div className={styles.subHeading}>
                        {new Date(item.date).toDateString().slice(4)}
                      </div>
                    </div>
                  )}
              })
            )}
          </div>
          <div className={styles.readMoreContainer}>
            <Link id="news" className={styles.readMore} to="/news/releases">
              View More
            </Link>
          </div>
        </div>
        <div className={styles.imgContainer}>
          <div className={styles.heading}>Articles</div>
          <div className={styles.widgetInfo}>
            {isEmpty(articles) ? (
              <div className={cx(styles.label, styles.noUpdates)}>
                No New Updates
              </div>
            ) : (
              articles.map((item, index) => {
                if (index > 2) return null;
                else if (item.fileUrl != null && item.fileUrl !== "null") {
                  return (
                    <div className={styles.info}>
                       <div className={styles.label}><a href={`https://backend.heliosx.net${item.fileUrl.slice(1)}`} target="_blank">{item.heading}</a></div>
                      <div className={styles.subHeading}>
                        {new Date(item.date).toDateString().slice(4)}
                      </div>
                    </div>
                  )}
                  else {
                    return (
                      <div className={styles.info}>
                         <div className={styles.label}>{item.heading}</div>
                        <div className={styles.subHeading}>
                          {new Date(item.date).toDateString().slice(4)}
                        </div>
                      </div>
                    )}
              })
            )}
          </div>
          <div className={styles.readMoreContainer}>
            <Link id="articles" className={styles.readMore} to="/news/articles">
              View More
            </Link>
          </div>
        </div>
        <div className={cx(styles.imgContainer, styles.twitter)}>
          <a
            class="twitter-timeline"
            href="https://twitter.com/HeliosX_lithium?ref_src=twsrc%5Etfw"
            style={{ marginBottom: "15px" }}
          >
            Tweets by Heliosx_lithium
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  news: state.news,
});

const mapDispatchToProps = {
  setNews,
  setArticles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Widgets);
