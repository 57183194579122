/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import lithium22 from "../../assets/lithium/lithium-22.png";
import styles from "./Technology.module.css";

function Technology(props) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        Lithium: The Key Ingredient Powering Today's Technology
      </div>
      <div className={styles.description}>
        Nature's lightest metal is also one of the most chemically reactive,
        making it a key ingredient in powering and building the latest
        technology. Soaring demand for the light metal has meant that the global
        lithium supply chain has had to grow nearly exponentially to keep up.
        Take a look through this infographic to learn more Lithium Facts.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <a
          href="https://www.visualcapitalist.com/lithium-key-ingredient-powering-todays-technology/"
          target="_blank"
        >
          <img className={styles.img} src={lithium22} alt="Lithium" />
        </a>
      </div>
    </div>
  );
}

Technology.propTypes = {};

export default Technology;
