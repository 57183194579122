import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";

import {
  addNews,
  addArticles,
  addReports,
  addFinancials,
  addInvestors,
} from "../../api/index";
import { isEmpty, sortBy } from "lodash-es";
import Form from "./Form";
import styles from "./Generic.module.css";

export const Generic = (props) => {
  const { token, data = [], callBack, heading, type } = props;
  const [stateData, setStateData] = useState(data);

  const fDataHandler = (data) => {
    let newFinancialData = [];
    let keys = Object.keys(data) || [];
    for (let i = 0; i < keys.length; i++) {
      (data[keys[i]].data || []).forEach((item) => {
        newFinancialData.push(item);
      });
    }
    return newFinancialData;
  };

  useEffect(() => {
    setStateData(data);
  }, [type, data.length]);

  const handleAddMore = () => {
    let data = {};
    let newArr = [];
    if (type === "news" || type === "articles") {
      data = {
        heading: "",
        date: new Date().toISOString().slice(0, 10),
        content: "",
        videoUrl: null,
        fileUrl: null,
      };
      newArr = [...stateData];
      newArr.push(data);
    } else if (type === "reports" || type === "investors") {
      data = {
        heading: "",
        fileUrl: null,
      };
      newArr = [...stateData];
      newArr.push(data);
    } else if (type === "financials") {
      data = {
        label: "",
        fileUrl: null,
        mdaUrl: null,
        year: 2021,
      };
      newArr = [...stateData];
      newArr.push(data);
    }

    switch (type) {
      case "news":
        addNews(data, token)
          .then((res) => {
            if (!isEmpty(res.payload)) {
              callBack();
              setStateData(newArr);
              cogoToast.success("Added new News, Please configure it!!");
            }
          })
          .catch(() => {
            cogoToast.error("Please try again!!");
          });
        break;
      case "articles":
        addArticles(data, token)
          .then((res) => {
            if (!isEmpty(res.payload)) {
              callBack();
              setStateData(newArr);
              cogoToast.success("Added new Articles, Please configure it!!");
            }
          })
          .catch(() => {
            cogoToast.error("Please try again!!");
          });
        break;
      case "reports":
        addReports(data, token)
          .then((res) => {
            if (!isEmpty(res.payload)) {
              callBack();
              setStateData(newArr);
              cogoToast.success("Added new Report, Please configure it!!");
            }
          })
          .catch(() => {
            cogoToast.error("Please try again!!");
          });
        break;
      case "investors":
        addInvestors(data, token)
          .then((res) => {
            if (!isEmpty(res.payload)) {
              callBack();
              setStateData(newArr);
              cogoToast.success("Added new Investor, Please configure it!!");
            }
          })
          .catch(() => {
            cogoToast.error("Please try again!!");
          });
        break;
      case "financials":
        addFinancials(data, token)
          .then((res) => {
            if (!isEmpty(res)) {
              callBack();
              let newArr = [...stateData];
              let arr = fDataHandler(res);
              newArr = [...newArr, ...arr];
              setStateData(newArr);
              cogoToast.success("Added new Financial, Please configure it!!");
            }
          })
          .catch(() => {
            cogoToast.error("Please try again!!");
          });
        break;
      default:
        return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Selected: {heading}</div>
      <div className={styles.addMore} onClick={handleAddMore}>
        Add More
      </div>

      {Array.isArray(stateData) &&
        sortBy(stateData, "id").map((item) => {
          let newItem = { ...item };
          if (type === "financials") {
            newItem.heading = item.label;
          }
          return (
            <div key={item.id}>
              <Form
                data={newItem}
                type={type}
                token={token}
                callBack={callBack}
              />
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Generic);
