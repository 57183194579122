/* eslint-disable no-restricted-globals */
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";
import cx from "classnames";

import {
  editNews,
  deleteNews,
  deleteArticles,
  editArticles,
  editReports,
  editFinancials,
  deleteReports,
  deleteFinancials,
  editInvestors,
  deleteInvestors,
} from "../../api/index";

import styles from "./Form.module.css";

export const Form = (props) => {
  const [editable, setEditable] = useState(false);

  const { data, type, token, callBack } = props;
  const {
    date: initialDate = "",
    heading: initialHeading = "",
    content: initialTextArea = "",
    videoUrl: initialVideoUrl = "",
    fileUrl: initialFileUrl = "",
    mdaUrl: initialMdaUrl = "",
    id,
    year: initialYear = null,
  } = data || {};
  const [date, setDate] = useState((initialDate || new Date().toISOString()).slice(0, 10));
  const [heading, setHeading] = useState(initialHeading);
  const [textArea, setTextArea] = useState(initialTextArea);
  const [fileUrl, setFileUrl] = useState(initialFileUrl);
  const [mdaUrl, setMdaUrl] = useState(initialMdaUrl);
  const [videoUrl, setVideoUrl] = useState(initialVideoUrl);
  const [year, setYear] = useState(initialYear);
  const fileInput = useRef(null);
  const mdaFileInput = useRef(null);

  const handleDate = (e) => {
    setDate(e.target.value);
  };
  const handleHeading = (e) => {
    setHeading(e.target.value);
  };

  const handleTextArea = (e) => {
    setTextArea(e.target.value);
  };

  const handleFileInput = () => {
    setFileUrl(fileInput.current.files[0]);
  };

  const handleMdaFileInput = () => {
    setMdaUrl(mdaFileInput.current.files[0]);
  };

  const handleVideoUrl = (e) => {
    setVideoUrl(e.target.value);
  };

  const handleEditable = () => {
    setEditable(!editable);
  };

  const handleYear = (e) => {
    setYear(e.target.value);
  };

  const handleSubmit = (e) => {
    if (
      !date &&
      (type === "news" || type === "articles" || type === "reports")
    ) {
      cogoToast.error("Please enter Date");
      return;
    }
    if (
      !heading &&
      (type === "news" || type === "articles" || type === "reports")
    ) {
      cogoToast.error("Please enter Heading");
      return;
    }
    if (!textArea && (type === "news" || type === "articles")) {
      cogoToast.error("Please enter Content");
      return;
    }

    let data = {};
    if (type === "news" || type === "articles") {
      data = {
        id,
        date,
        heading,
        content: textArea,
        videoUrl,
        fileUrl,
      };
    } else if (type === "reports") {
      data = {
        id,
        heading,
        fileUrl,
        date,
      };
    } else if (type === "investors"){
      data = {
        id,
        heading,
        fileUrl,
      };
    }
    else if (type === "financials") {
      data = {
        id,
        label: heading,
        fileUrl,
        mdaUrl,
        year,
      };
    }

    switch (type) {
      case "news":
        editNews(data, token).then((res) => {
          cogoToast.success("Successfully Updated!!");
          callBack();
          setEditable(false);
        });
        break;
      case "articles":
        editArticles(data, token).then((res) => {
          cogoToast.success("Successfully Updated!!");
          callBack();
          setEditable(false);
        });
        break;
      case "reports":
        editReports(data, token).then((res) => {
          cogoToast.success("Successfully Updated!!");
          callBack();
          setEditable(false);
        });
        break;
      case "financials":
        editFinancials(data, token).then((res) => {
          cogoToast.success("Successfully Updated!!");
          callBack();
          setEditable(false);
        });
        break;
      case "investors":
        editInvestors(data, token).then((res) => {
          cogoToast.success("Successfully Updated!!");
          callBack();
          setEditable(false);
        });
        break;
      default:
        return null;
    }
  };
  const handleDelete = () => {
    if (confirm("Are you sure, you want to delete this")) {
      switch (type) {
        case "news":
          deleteNews(id, token).then(() => {
            cogoToast.success("News Successfully Deleted!!");
            callBack();
            setEditable(false);
          });
          break;
        case "articles":
          deleteArticles(id, token).then(() => {
            cogoToast.success("Article Successfully Deleted!!");
            callBack();
            setEditable(false);
          });
          break;
        case "reports":
          deleteReports(id, token).then(() => {
            cogoToast.success("Report Successfully Deleted!!");
            callBack();
            setEditable(false);
          });
          break;
        case "financials":
          deleteFinancials(id, token).then(() => {
            cogoToast.success("Financial Successfully Deleted!!");
            callBack();
            setEditable(false);
          });
          break;
        case "investors":
          deleteInvestors(id, token).then(() => {
            cogoToast.success("Investor Successfully Deleted!!");
            callBack();
            setEditable(false);
          });
          break;
        default:
          return null;
      }
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.button}>
        <div className={cx(styles.input, styles.edit)} onClick={handleEditable}>
          {editable ? "Please Submit to reflect changes" : "Edit"}
        </div>
        <div className={cx(styles.input, styles.delete)} onClick={handleDelete}>
          Delete
        </div>
      </div>
      {(type === "news" || type === "articles" || type === "reports") && (
        <div className={styles.input}>
          <label>Date: &nbsp;&nbsp;</label>
          <input
            name="date"
            type="date"
            onChange={handleDate}
            value={date}
            disabled={!editable}
          />
        </div>
      )}
      {type === "financials" && (
        <div className={styles.input}>
          <label>Year: &nbsp;&nbsp;</label>
          <input
            name="year"
            type="number"
            step="1"
            value={year}
            onChange={handleYear}
          />
        </div>
      )}
      <div className={styles.input}>
        <label>Heading: &nbsp;&nbsp;</label>
        <input
          name="heading"
          type="text"
          className={styles.headingInput}
          onChange={handleHeading}
          value={heading}
          disabled={!editable}
        />
      </div>
      {(type === "news" || type === "articles") && (
        <div className={styles.input}>
          <label>Content: &nbsp;&nbsp;</label>
          <textarea
            name="content"
            rows="4"
            cols="50"
            onChange={handleTextArea}
            value={textArea}
            disabled={!editable}
          />
        </div>
      )}
      <div className={styles.input}>
        <label>File Upload: &nbsp;&nbsp;</label>
        <input
          ref={fileInput}
          type="file"
          id="myfile"
          onChange={handleFileInput}
          disabled={!editable}
        />
        <br />
        {!editable && fileUrl && (
          <a
            href={`${process.env.REACT_APP_BACKEND_URL}/${fileUrl.slice(1)}`}
            target="_blank"
          >
            View File
          </a>
        )}
      </div>
      {type === "financials" && (
        <div className={styles.input}>
          <label>MDA File Upload: &nbsp;&nbsp;</label>
          <input
            ref={mdaFileInput}
            type="file"
            id="myfile_2"
            onChange={handleMdaFileInput}
            disabled={!editable}
          />
          <br />
          {!editable && mdaUrl && (
            <a
              href={`https://backend.heliosx.net${mdaUrl.slice(1)}`}
              target="_blank"
            >
              View File{" "}
            </a>
          )}
        </div>
      )}
      {(type === "news" || type === "articles") && (
        <div className={styles.input}>
          <label>Video Url: &nbsp;&nbsp;</label>
          <input
            name="videoUrl"
            type="text"
            onChange={handleVideoUrl}
            value={videoUrl}
            disabled={!editable}
          />
        </div>
      )}
      <div className={styles.input}>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
