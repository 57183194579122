import React, { useState, Fragment } from "react";
// import PropTypes from 'prop-types'

import styles from "./Market.module.css";
function Market(props) {
  const [type, setType] = useState("");
  const renderIframe = () => {
    switch (type) {
      case "tsx":
        return (
          <Fragment>
            <div className={styles.heading}>TSX Venture Exchange</div>
            <iframe
              title="tsx_venture"
              id="tradingview_d854c"
              src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_d854c&symbol=HX&interval=D&saveimage=1&toolbarbg=f1f3f6&studies=%5B%5D&hideideas=1&theme=White&style=1&timezone=America%2FToronto&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=dajin.ca&utm_medium=widget&utm_campaign=chart&utm_term=HX"
              frameBorder="0"
              allowtransparency="true"
              scrolling="no"
              allowfullscreen=""
              className={styles.iframe}
            />
          </Fragment>
        );
      case "otc":
        return (
          <Fragment>
            <div className={styles.heading}>OTC Market</div>
            <iframe
              title="otc_market"
              frameBorder="0"
              scrolling="yes"
              src="https://www.otcmarkets.com/stock/HXLTF/quote"
              width="100%"
              allowfullscreen=""
              className={styles.iframe}
            />
          </Fragment>
        );
      case "german":
        return (
          <Fragment>
            <div className={styles.heading}>German Market</div>
            <iframe
              title="german_market"
              align="left"
              frameBorder="0"
              scrolling="yes"
              allowfullscreen=""
              src="https://www.finanznachrichten.de/aktienkurse-boersen/dajin-resources-corp.htm"
              className={styles.iframe}
            />
          </Fragment>
        );
      default:
        return null;
    }
  };

  const handleClick = (type) => {
    setType(type);
  };
  return (
    <div className={styles.container}>
      <div className={styles.markets}>
        <div className={styles.heading} onClick={() => handleClick("tsx")}>
          TSX Venture Exchange
        </div>
        <div className={styles.heading} onClick={() => handleClick("otc")}>
          OTC Market
        </div>
        <div className={styles.heading} onClick={() => handleClick("german")}>
          German Market
        </div>
      </div>
      {renderIframe()}
    </div>
  );
}

Market.propTypes = {};

export default Market;
