import { withRouter } from "react-router-dom";
import { Header, Footer, Content } from "../shared";
import { Container } from "react-bootstrap";
import styles from "./Layout.module.css";

function layout(props) {
  const { children } = props;
  return (
    <Container fluid className={styles.container}>
      <Header {...props} />
      <Content>{children}</Content>
      <Footer />
    </Container>
  );
}

export default withRouter(layout);
