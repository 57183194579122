import React from "react";
import styles from "./VideoGallery.module.css";

function VideoGallery(props) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Video Gallery</div>

      <div className={styles.heading}>
        Tesla Brings Battery Power Home: What You Need to Know
      </div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid390181"
          parent-selector="#content"
          src="https://www.youtube.com/embed/iEhON2UIxCU?rel=0&amp;?hd=1"
          title="YouTube video player"
        ></iframe>
      </p>

      <div className={styles.heading}>Lithium Applications &amp; Potential</div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid465913"
          parent-selector="#content"
          src="https://player.vimeo.com/video/57224466?byline=0&amp;portrait=0"
          webkitallowfullscreen=""
        ></iframe>
      </p>

      <div className={styles.heading}>
        Orocobre Limited Opening Ceremony of the Olaroz Lithium Project
      </div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid718974"
          parent-selector="#content"
          src="https://www.youtube.com/embed/LSITYbxUI-I?rel=0&amp;?hd=1"
          title="YouTube video player"
        ></iframe>
      </p>

      <div className={styles.heading}>
        Crash Course: Equity Financing in the Mining Sector
      </div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid580595"
          parent-selector="#content"
          src="https://www.youtube.com/embed/MOZszeZ95tk?rel=0&amp;?hd=1"
          title="YouTube video player"
        ></iframe>
      </p>

      <div className={styles.heading}>
        One-on-one with Elon Musk: MIT AeroAstro Centennial Symposium 2014
        (Gigafactory)
      </div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid718974"
          parent-selector="#content"
          src="https://www.youtube.com/embed/41wj31tfkkk?rel=0&amp;?hd=1"
          title="YouTube video player"
        ></iframe>
      </p>

      <div className={styles.heading}>Nissan LEAF: Thanks a Billion</div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid580595"
          parent-selector="#content"
          src="https://www.youtube.com/embed/Nn__9hLJKAk?rel=0&amp;?hd=1"
          title="YouTube video player"
        ></iframe>
      </p>

      <div className={styles.heading}>The Growing Demand for Lithium</div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid580595"
          parent-selector="#content"
          src="https://www.youtube.com/embed/B39hDHxwpxM?rel=0&amp;?hd=1"
          title="YouTube video player"
        ></iframe>
      </p>

      <div className={styles.heading}>
        Formula E: The World’s First Electric Car Race
      </div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid134298"
          parent-selector="#content"
          src="https://www.youtube.com/embed/_ayQAf80XpQ?rel=0&amp;?hd=1"
          title="YouTube video player"
        ></iframe>
      </p>

      <div className={styles.heading}>Energy 101: Electric Vehicles</div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid580595"
          parent-selector="#content"
          src="https://www.youtube.com/embed/M69GBL0IDzI?rel=0&amp;?hd=1"
          title="YouTube video player"
        ></iframe>
      </p>

      <div className={styles.heading}>Argentina Travel Video Guide</div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid580595"
          parent-selector="#content"
          src="https://www.youtube.com/embed/w7y0xRIuGP0?rel=0&amp;?hd=1"
          title="YouTube video player"
        ></iframe>
      </p>

      <div className={styles.heading}>Via Motors VTRUX - Jay Leno's Garage</div>

      <p style={{ textAlign: "center", height: "400px" }}>
        <iframe
          allowfullscreen=""
          frameBorder="0"
          width="100%"
          height="100%"
          id="fitvid580595"
          parent-selector="#content"
          src="https://www.youtube.com/embed/p5STy6HRZEQ?rel=0&amp;?hd=1"
          title="YouTube video player"
        ></iframe>
      </p>
    </div>
  );
}

VideoGallery.propTypes = {};

export default VideoGallery;
