import React from "react";
// import PropTypes from "prop-types";

import Video from "./Video";
import About from "./About";
import Projects from "./Projects";
import Widgets from "./Widgets";
import styles from "./Home.module.css";

function Home(props) {
  return (
    <div className={styles.container}>
      <Video />
      <About />
      <Widgets />
      <Projects />
    </div>
  );
}

Home.propTypes = {};

export default Home;
