import React from "react";
// import PropTypes from 'prop-types'
import alkali1 from "../../assets/projects/alkali-1.jpg";
import alkali2 from "../../assets/projects/alkali-2.jpg";

import styles from "./AlkaliLake.module.css";

function AlkaliLake(props) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        Alkali Spring valley Lithium Project – Nevada
      </div>
      <div className={styles.subHeading}>(also known as Alkali Lake)</div>
      <div className={styles.description}>
        Dajin Resources (US) Corp., a wholly owned subsidiary of Dajin Lithium
        Corp. holds a 100% interest in 62 placer mineral claims covering 1,240
        acres in Alkali Spring valley.
      </div>
      <div className={styles.heading}>Location</div>
      <div className={styles.subHeading}>Esmeralda County, Nevada.</div>
      <div className={styles.description}>
        The Alkali Spring valley (also known as Alkali Lake) Lithium project is
        located approximately 12 km (7.5 mi) northeast of Albemarle
        Corporation’s Silver Peak Lithium Brine operation in the Clayton Valley,
        near Silver Peak, Nevada.
      </div>
      <div className={styles.heading}>Description</div>
      <div className={styles.description}>
        The Alkali Spring valley (also known as Alkali Lake) Lithium project is
        similar to the Clayton Valley geology with a classic fault bound closed
        basin. There is an active geothermal system, Alkali Hot Springs, along
        the southern margin of the basin. Just as with circulating basin waster,
        deep fluids, particularly hot ones associated with the geothermal
        resource, dissolved rocks, liberate Boron, Lithium and other elements
        such as sodium chloride. These minerals are brought to the surface as
        springs emanating from the faults bounding the playa (dry lake bed). The
        association of a closed basin with an arid environment, a geothermal
        system and plentiful sources of Lithium suggest that Alkali Lake may
        host significant Lithium rich brine.
      </div>
      <img className={styles.img} src={alkali1} alt="alkali-lake" />
      <div className={styles.heading}>Exploration Program</div>
      <div className={styles.description}>
        Dajin Resources (US) Corp. carried out and reviewed a gravity survey at
        Alkali Spring valley. The survey was completed by Magee Geophysical
        Services LLC of Reno, Nevada and the 3D gravity and airborne magnetic
        basin modeling was prepared by Wright Geophysics Inc. of Elko, Nevada.
        The gravity model indicates the claim area is underlain by a circular
        basin estimated at 4,000 feet in depth (1,200 m) and a second basin
        exists approximately 3 km to the east. The two basins are separated by a
        northwest southeast trending structure likely related to the Walker Lane
        Shear Zone. The depth of the eastern basin ranges from 3,000 to 4,000
        feet (1,000 – 1,200 meter). Surface sampling in March & April 2015,
        confirmed the presence of near surface lithium in the western basin.
      </div>
      <img className={styles.img} src={alkali2} alt="alkali-lake" />
      <div className={styles.heading}>Background and Surrounding Area</div>
      <div className={styles.description}>
        The Alkali Spring valley (also known as Alkali Lake) property is a grass
        roots property that is located within 12km of the only operating brine
        based Lithium mine in North America. In 2014 Albemarle Corporation paid
        US$6.2 billion in cash and stock for Rockwood Holdings Inc. which
        included the Clayton Valley, Rockwood Lithium mine. The Silver Peak area
        is one of the oldest mining areas in Nevada as this area produced
        substantial amounts of silver, gold and other minerals. In 1967 the
        Rockwood Lithium mine began operations to mine Lithium by using the
        method of low cost evaporation ponds and has produced Lithium since
        then. This general area is surrounded by Lithium-enriched tertiary
        rhyolite tuffs and Lithium-bearing sediments, as well as active
        geothermal systems. Over time, the Lithium has become mobilized from
        these sources and deposited into the ground water.
        <br />
        In 2010 the Rockwood Lithium mine received a $28.4 million grant from
        the U.S. Department of Energy to expand and upgrade the production for
        battery grade usage. It is estimated that since initial production began
        the Rockwood Lithium mine produced over 150,000 tons of Lithium and has
        in ground reserve estimates of 382,000 tons of Lithium.
      </div>
    </div>
  );
}

AlkaliLake.propTypes = {};

export default AlkaliLake;
