import React from "react";
// import PropTypes from "prop-types";

import salina1 from "../../assets/projects/salina-1.png";
import salina2 from "../../assets/projects/salina-2.jpg";
import salina3 from "../../assets/projects/salina-3.jpg";
import salina4 from "../../assets/projects/salina-4.jpg";
import salina5 from "../../assets/projects/salina-5.jpg";
import salina6 from "../../assets/projects/salina-6.jpg";
import salina7 from "../../assets/projects/salina-7.jpg";

import styles from "./SalinasGrandes.module.css";

function SalinasGrandes(props) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        Guayatoyoc Salar Located in Northwestern – Argentina
      </div>
      <div className={styles.description}>
        93,000 hectares <br />
        230,000 acres <br />
        25 concessions <br />
        The concessions are 49% owned by Dajin Resources Corp. and 51% owned by
        Pluspetrol Resources Corporation B.V.
      </div>

      <div className={styles.heading}>Agreements</div>
      <div className={styles.subHeading}>
        On November 11, 2017, Dajin Resources S.A. received exploration permits
        for San Jose/Navidad minas.{" "}
      </div>
      <div className={styles.description}>
        On August 8, 2016, Dajin's wholly owned Argentinian subsidiary, Dajin
        Resources S. A., signed a binding Memorandum of Understanding ("MOU")
        with LSC Lithium Corporation ("LSC") whereby LSC was granted an option
        to earn a 51% interest in Dajin's Lithium properties in Argentina. On
        October 27, 2016, Dajin reported that it has completed the share
        purchase agreement with LSC. In March 2019, Pluspetrol Resources
        Corporation B.V. acquired all of the issued and outstanding shares of
        LSC Lithium Corporation for approximately CDN $111 million. LSC Lithium
        held an agreement to acquire a 51% interest in Dajin Resources S.A with
        the exploration expenditure of CDN $2,000,000 on the Guayatoyoc
        salar. In October 2019, Pluspetrol earned a 51% interest in Dajin
        Resources S.A. with the expenditure of US$1,250,000 on the Salinas
        Grandes salar and payment of US$600,000 to Dajin Resources Corp.
      </div>

      <div className={styles.heading}>Location</div>
      <div className={styles.subHeading}>Jujuy province, Argentina.</div>
      <div className={styles.description}>
        Access to the mineral concessions is by a paved national highway with
        several local population centers serviced by a regional electric grid.
      </div>
      <iframe
        title="guayatoyoc"
        frameBorder="0"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29337.46442386712!2d-65.88417961883817!3d-23.199998631248476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94036271c62c5fd7%3A0xf463ce97be9f0eb7!2sGuayatayoc%2C%20Jujuy%2C%20Argentina!5e0!3m2!1sen!2sin!4v1643828784694!5m2!1sen!2sin"
        className={styles.iframe}
      ></iframe>
      
      <div className={styles.heading}>Exploration Potential</div>
      <div className={styles.description}>
        Dajin Resource Corp. (Dajin) holds a 49% working interest in concessions
        or concession applications in Jujuy province of Argentina covering
        regions known to or believed to contain brines rich in Lithium,
        Potassium and Boron. The concessions total approximately 93,000 hectares
        (230,000 acres) in various drainage basins including 51,970 hectares
        (128,421 acres) of salar (salt lake) in Guayatoyoc and 41,068
        hectares (101,481 acres) in Guayatayoc salt lake basin. salar (salt
        lake) in Guayatoyoc and 41,068 hectares (101,481 acres) in
        Guayatayocl salt lake basin. Guayatoyoc/Guayatayoc salt lakes basin
        was chosen as Dajin’s principal focus for Lithium exploration on the
        basis of data that are published in various scientific publications,
        indicating both permissive brine chemistries accompanied by Lithium
        concentrations in surface and near surface waters that are within the
        range of Lithium concentrations being produced elsewhere and enabling
        infrastructure.
        <br />
        The Guayatoyoc/Guayatayoc salt lakes basin is contained in a closed
        basin that is fault bonded to the west forming a Tertiary age
        sedimentary basin. Dajin has completed an interpretation of seismic data
        covering part of its acquisitions and has identified a series of salt
        horizons that are very prospective for brines rich in Lithium, Boron and
        Potassium. Based on seismic interpretations the basin may be as much as
        800 metres thick and contain additional salt layers, below and adjacent
        to the current salt beds, which are believed to have been deposited
        during earlier climatic maxima. The older salt beds are similar in
        thickness to the current salt deposits in that seismic profiles indicate
        the salt beds may be as much as 150 to 200 metres thick. The potential
        exists for the older brines that flood the sedimentary/structural basins
        to contain substantially greater concentrations of Lithium and Potassium
        than the current near surface brines due to the more extreme climatic
        conditions extant during earlier climatic maxima when these older salt
        deposits were precipitated.
        <br />
        Anomalously high Lithium concentrations, up to 1,600 ppm, in near
        surface brines present in specific regions of Guayatoyoc salt lake
        are believed to be due to recharge of the salt lake by seepage of paleo
        brines along faults bounding the sedimentary/structural basins.
        Additional advantages to a production plan based on the brine flooded
        sediments anticipated in the sedimentary/structural basins is that the
        specific yield of Lithium in brine from the clastic sediments is not
        expected to be restricted by either low permeability due to
        recrystalizing salt or brine dilution during periodic flooding of
        Guayatoyoc by fresh water.
      </div>
      <img className={styles.img} src={salina1} alt="guayatoyoc" />
      <div className={styles.description}>
        Guayatoyoc is approximately 70 km to the limited east of the
        recently completed Lithium processing plants of Cauchari-Olaroz Lithium
        Americas Corp. and Orocobre.
      </div>
      <img className={styles.img} src={salina2} alt="guayatoyoc" />
      <div className={styles.description}>
        A series of high elevation closed topographic basins that trend
        generally parallel to the axis of the Andes in Chile, Argentina,
        Bolivia, Peru and Ecuador are the sites of brine accumulation in salt
        water lakes. These brines are variably rich in Lithium, Potassium, Boron
        and other metals. The brines reportedly form mineable resources of
        Lithium, Potassium and Boron in Chile, Argentina and Bolivia. To the
        best of the author’s knowledge, in South America the cited metals are
        only produced in Chile, where Lithium, Potassium and Boron are produced
        at Salar Atacama, and in Argentina where Lithium is produced directly
        from the brines at Salar Hombre Muerto.
        <br />
        In addition to the aforementioned locations, other projects in late
        stage exploration are the brines in the salars Rincón, Olaroz, Salinas
        Grandes and Antofalla in Argentina; Uyuni in Bolivia and Coipasa in
        Chile. The table that follows is a summary of chemical data from salt
        lakes or brine pools in South America and in the United States that are
        sources of Lithium production or that have been in the past or for which
        exploration projects have recently been proposed. It is clear from the
        comparison that viability of a Lithium brine project depends on more
        than the absolute Lithium grade of the deposit.
        <br />
        Lithium is produced as one of several byproducts at most brine
        operations and the cost of production depends on the chemistries of the
        brines used as source materials for the solar evaporation ponds.
        Generally the greater the quantity of Magnesium and Calcium present in
        the brine relative to the Lithium grade and the more complex the
        chemistry of the brine the greater the costs of production will be.
      </div>
      <img className={styles.img} src={salina3} alt="guayatoyoc" />

      <div className={styles.heading}>Exploration</div>
      <div className={styles.description}>
        In 2016, Dajin Resources Corp. signed an earn-in agreement with LSC
        Lithium Corp. As per the agreement LSC was appointed the operator for
        Dajin Resources S.A.. On November 11, 2017 Dajin Resources S.A. received
        exploration permits for the San Jose-Navidad minas, part of Dajin S.A.’s
        Guayatoyoc land package. Exploration was carried out in late
        December 2017 on a portion of the minas and the results were released
        February 28, 2018. Twenty-five (25) shallow brine samples were taken
        from an area of 550 hectares, 1,360 acres (5.5 km2) in the northwestern
        corner of the 4,400 hectare, 10,625 acres (43 km2) San Jose-Navidad
        minas. Concentrations range from 281 mg/l to 1,353 mg/l, averaging 591
        mg/l Lithium. Of the analysis taken, 60% of assays returned greater than
        500 mg/l Lithium and 16% were greater than 800 mg/l Lithium. The highest
        Lithium brine concentration value was 1,353 mg/l.
        <br />
        In March, 2019 Pluspetrol Resources Corporation B.V. ("Pluspetrol")
        acquired 100% of the issued and outstanding common shares of LSC Lithium
        Corporation for a cash consideration of approximately CDN $111million.
        Dajin is now partnered with Pluspetrol who have earned a 51% interest in
        Dajin Resources S.A. with the expenditure of US$1,250,000 on the
        concessions and payment of $600,000 to Dajin Resources Corp. Pluspetrol
        has created the company Litica Resources S.A. for exploration and
        development of their concessions. Litica's exploration team is currently
        reviewing the Guayatoyoc and Guayatayoc salars exploration and
        drilling data acquired in the LSC Litium transaction. Pluspetrol is a
        private, independent, international energy company with over 40 years
        experience in the exploration and production of oil and gas. Pluspetrol
        is the largest hydrocarbon producer in Peru. With operations in Angola,
        Argentina, Bolivia, Colombia, Peru, and offices in United States,
        Uruguay and the Netherlands, Pluspetrol promotes energy development and
        fosters activities at an international level. Pluspetrol has created the
        company Litica Resources S.A. to enter the "battery metals" business.
      </div>
      <img className={styles.img} src={salina4} alt="guayatoyoc" />

      <div className={styles.heading}>Other Agreements</div>
      <div className={styles.description}>
        In July, 2015, Dajin signed an agreement with the Cooperativa and
        Community of Tres Morros for the right to explore the San Jose and
        Navidad concessions. This agreement gives Dajin the right to explore the
        4,400 hectare (10,625 acres) San Jose- Navidad concessions within the
        Guayatoyoc salar. The mining leases held by the Cooperative,
        “Navidad” and “San Jose”, cover a total area of 4,300 hectares (10,625
        acres). An initial payment of $US20,000 has been made and the
        Cooperativa now must acquire the exploration permits to receive a
        further payment of $US40,000. These permits will be the first to be
        granted to Dajin for exploration work in Guayatoyoc, a salar where
        Dajin has staked ground of 80,248 hectares (198,000 acres).
      </div>
      <img className={styles.img} src={salina5} alt="guayatoyoc" />
      <div className={styles.description}>
        On February 27, 2016, Former Dajin Director, Dr. Catherine Hickson met with
        representatives of the Tres Morros community and signed an agreement of
        cooperation for the exploration phase of the project. Photo below is
        Andrés Castillo, Tres Morros community leader, and Hickson signing the
        accord.
      </div>
      <img className={styles.img} src={salina6} alt="guayatoyoc" />
      <div className={styles.description}>
        The agreement was signed in Jujuy, Argentina. Below, from left to right
        Andrés Castillo (Tres Morros community leader), Vidal Aguirre (Head of
        Tres Morros Cooperativa), Arturo Pfister (lawyer for the Cooperativa),
        Cosme Beccar-Varela (President, Dajin Argentina), and Catherine Hickson
        (Former Director, Dajin Resources Corp).
      </div>
      <img className={styles.img} src={salina7} alt="guayatoyoc" />
      <div className={styles.description}>
        Dajin’s management has established a close relationship with the local
        population, having provided their village with fresh drinking water
        through a well commissioned by Dajin as well as having repaired and
        rebuilt homes and businesses damaged in multiple wind storms.
      </div>
    </div>
  );
}

SalinasGrandes.propTypes = {};

export default SalinasGrandes;
