/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import lithium23 from "../../assets/lithium/lithium-23.png";
import styles from "./Revolution.module.css";

function Revolution(props) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>The Lithium Revolution</div>
      <div className={styles.description}>
        The shift to clean energy has opened a window of opportunity for energy
        metals, such as Lithium. For clean energy to be useful to society, the
        efficient storage of energy is paramount. Advanced batteries allow our
        cars to run without using gasoline and batteries allow renewable energy
        to be stored when the sun isn't shining and the wind isn't blowing.
        <br />
        <br />
        In recent years, lithium-ion batteries have emerged as the most widely
        used choice for energy storage. Simply put, energy metals, such as
        lithium, graphite, cobalt, uranium and copper, are metals used in the
        creation or storage of energy. Find out more by checking out this
        infographic on why Lithium is the key ingredient in today's technology.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <a
          href="https://www.visualcapitalist.com/the-lithium-revolution/"
          target="_blank"
        >
          <img border="0" className={styles.img} src={lithium23} alt="lithium" />
        </a>
      </div>
    </div>
  );
}

Revolution.propTypes = {};

export default Revolution;
