/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash-es";

import { FaRegFilePdf } from "react-icons/fa";

import { getNews } from "../../api/index";
import { setArchivedNews } from "../../Redux/Reducers/news";

import styles from "./News.module.css";

export const ArchivedNews = (props) => {
  const { news, setArchivedNews } = props;
  useEffect(() => {
    if (isEmpty(news?.archivedNews)) {
      getNews().then((res) => {
        if (res.status === 200) {
          let latestNews = (res.data.payload || []).filter((item) => {
            return new Date(item.date).getFullYear() < 2021;
          })
          setArchivedNews(latestNews);
        }
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Archived News Releases</div>
      {!isEmpty(news?.archivedNews) &&
        news?.archivedNews.map((item) => {
          return (
            <div className={styles.newsContainer}>
              <div className={styles.pdfContainer}>
                <div className={styles.label}>
                  {new Date(item.date).toDateString().slice(4)}
                </div>
                <div className={styles.fileDetails}>
                  <div className={styles.description}>{item.heading}</div>
                  {item.fileUrl && item.fileUrl !== "null" && (
                    <div className={styles.pdfFiles}>
                      <a
                        className={styles.link}
                        target="_self"
                        rel="noreferrer"
                        href={`${process.env.REACT_APP_BACKEND_URL}/${(
                          item.fileUrl || ""
                        ).slice(1)}`}
                      >
                        <FaRegFilePdf size={25} /> VIEW
                      </a>
                      <a
                        className={styles.link}
                        target="_blank"
                        rel="noreferrer"
                        href={`${process.env.REACT_APP_BACKEND_URL}/${(
                          item.fileUrl || ""
                        ).slice(1)}`}
                        download
                      >
                        <FaRegFilePdf size={25} /> PDF
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <strong>Brief:</strong> {item.content}
              {item.videoUrl &&
                item.videoUrl !== "undefined" &&
                item.videoUrl !== "null" && (
                  <iframe
                    allowfullscreen=""
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    id={item.id}
                    parent-selector="#content"
                    src={item.videoUrl}
                    title="YouTube video player"
                  ></iframe>
                )}
            </div>
          );
        })}
    </div>
  );
};

ArchivedNews.propTypes = {
  prop: PropTypes.object,
};

const mapStateToProps = (state) => ({
  news: state.news,
});

const mapDispatchToProps = {
  setArchivedNews,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedNews);
