import React, { memo } from "react";
import { Link } from "react-router-dom";
import cx from 'classnames';

import isMobileDevice from "../../utils/isMobile";
import project1 from "../../assets/home/project-1.jpg";
import project2 from "../../assets/home/project-2.jpg";
import project3 from "../../assets/home/project-3.jpg";
import map from "../../assets/home/projects_map.png";
import mapMobile from "../../assets/home/projects_map_mobile.png";

import styles from "./Projects.module.css";


function Projects() {
  const isMobile = isMobileDevice();
  const image = isMobile ? mapMobile : map;
  return (

    <div className={styles.container}>
      <div className={styles.heading}>Our Projects</div>
      <div className={cx(styles.projects, styles.imgmap)}>
        {isMobile ? <Link to="/projects" style={{ textDecoration: 'none' }}>
          <img src={image} usemap="#image-map" alt="Projects" />
        </Link> : <img src={image} usemap="#image-map" alt="Projects" />
        }

        <map name="image-map">
          <area target="" alt="Teels" title="Teels" href="/projects/teels-marsh" coords="317,413,504,461" shape="rect" />
          <area target="" alt="Guayatoyoc" title="Guayatoyoc" href="/projects/guayatoyoc" coords="614,275,748,322" shape="rect" />
          <area target="" alt="Projects" title="Projects" href="/projects" coords="262,142,393,198" shape="rect" />
        </map>
      </div>
    </div>
  );
}



export default memo(Projects);

