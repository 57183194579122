const routes = {
  home: '/',

  aboutUs: '/about',
  about: '/about/management-team',
  responsibility: '/about/responsibility',

  investors: '/investors',
  investorsInfo: '/investors/info',
  market: '/investors/market',
  reports: '/investors/reports',
  financials: '/investors/financials',

  projects: '/projects',
  teelsMarsh: '/projects/teels-marsh',
  alkaliLake: '/projects/alkali-lake',
  salinasGrandes: '/projects/guayatoyoc',

  lithium: '/lithium',
  articles: '/lithium/articles',
  lithiumMarket: '/lithium/market',
  supplyDemand: '/lithium/supply-demand',
  products: '/lithium/products',
  reserves: '/lithium/reserves',
  gallery: '/lithium/gallery',
  technology: '/lithium/technology',
  revolution: '/lithium/revolution',

  contact: '/contact',

  news: '/news',
  releases: '/news/releases',
  newsArticles: '/news/articles',
  archivedReleases: '/news/arc-press',
  archivedNewsArticles: '/news/news-archived',

  login: '/login',
  dashboard: '/dashboard',
};

export default routes;