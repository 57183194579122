/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import styles from "./Contact.module.css";

function Contact(props) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Contact</div>
      <div className={styles.heading}>Head Office, Canada</div>
      <div className={styles.description}>
        Brian Findlay, President,
        <br />
        1500-701 West Georgia Street, Vancouver BC, V7Y 1C6
        <br />
        Phone: 604.681.6151
        <br />
        Email: info@heliosx.ca; IR@heliosx.ca
        <br />
      </div>

      <div className={styles.heading}>Field Office, Argentina</div>
      <div className={styles.description}>
        Dajin Resources S.A.
        <br />
        Beccar Cosme M. Varela C&C
        <br />
        Beccar Varela Reconquista 657 Piso 1 C1003ABM Buenos Aires, Argentina
        <br />
        Phone: 011.54.11.4315.2444
        <br />
      </div>

      <div className={styles.heading}>Transfer Agent</div>
      <div className={styles.description}>
        Odyssey Trust Co.
        <br />
        323-409 Granville Street, Vancouver BC, V6C 1T2
        <br />
        Phone: 778.819.1184
        <br />
      </div>

      <div className={styles.heading}>Legal Council</div>
      <div className={styles.description}>
        Borden Ladner Gervais LLP
        <br />
        1200 Waterfront Centre,
        <br />
        200 Burrard Street, Vancouver, BC, V7X 1T2
        <br />
        Phone: 604.687.5744
        <br />
      </div>
      <div className={styles.heading}>Chartered Accounts</div>
      <div className={styles.description}>
        DeVisser Gray LLP
        <br />
        401-905 West Pender Street, Vancouver BC, V6C 1L6
        <br />
        Phone: 604.687.5447
        <br />
      </div>
    </div>
  );
}

Contact.propTypes = {};

export default Contact;
