import React from "react";
// import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";

import navigation from "../../data/navigation.json";

import styles from "./Footer.module.css";

function Footer(props) {
  const { location } = props;
  return (
    <div className={styles.container}>
      <div>
        {navigation.data.map((item) => {
          let selected = false;
          let link = item.link;
          if (location.pathname === "/" && item.key === "home") {
            selected = true;
          }
          if (link === "/news") {
            link = "/news/releases"
          }
          return (
            <Link
              to={link}
              key={item.key}
              className={cx(styles.label, {
                [styles.selected]:
                  location.pathname.includes(item.key) || selected,
              })}
            >
              {item.label}
            </Link>
          );
        })}
      </div>
      <div className={styles.copyright}>
        © Copyright 2022 HeliosX
        <br />
        All project entities, reports and intellectual property are wholly owned by HeliosX Lithium & Technologies Corp. as of the Shareholder’s unanimous approval of the Plan of Arrangement between Dajin Lithium Corp. and HeliosX Lithium & Technologies Corp on November 23, 2021.
        <br />
        <br />
        Site is Developed and Maintained by CreatrSlate
      </div>
    </div >
  );
}

Footer.propTypes = {};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
