import React, { Fragment, useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash-es";
import cx from "classnames";
import { FaTwitter, FaFacebook, FaLinkedinIn } from 'react-icons/fa';
import { Navbar } from "react-bootstrap";
import logo from "../../assets/images/HeliosXfullcolor.jpg";

import { setHeaderData, setTsxData } from "../../Redux/Reducers/header";

import navigation from "../../data/navigation";

import styles from "./Header.module.css";

function Header(props) {
  const { location, header, setHeaderData, setTsxData } = props;
  const { data, tsx } = header;
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (isEmpty(data)) {
      fetch(
        "https://backend.otcmarkets.com/otcapi/stock/trade/inside/HXLTF?symbol=HXLTF"
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setHeaderData(data);
        });
    }

    if (isEmpty(tsx)) {
      let dateOffset = 24 * 60 * 60 * 1000;
      let now = new Date();
      let futureDate = now.setTime(now.getTime() + dateOffset);
      futureDate = new Date(futureDate).toISOString().slice(0, 10);
      now = now.toISOString().slice(0, 10);
      fetch(
        `https://api.stockdio.com/data/financial/prices/v1/getHistoricalPrices/?&from=${futureDate}&to=${now}&app-key=21B7986BA1134484B930C42100DC809E&symbol=DJI&stockExchange=tsxv&dfx_date=Ypon7tBychaTSZv6q4L5kSF0g8G2DJlDzd7Jc3%2b6SifAikdWWMxuXdKSvnOqgsZKdE3k12CsPLdNW1YBRGLl0wATFiJSMThytz88S5fymx9dFflUZdnYOAJREZAOW5rIz%2ftofyMS67OBygMXfuN9Q5tze8F9T0OpOYGoUOIeq2A%3d&compress=true&useAdjusted=true`
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setTsxData(data.data);
        });
    }
  }, []);
  const renderNavigation = (headers) => {
    return headers.map((header, index) => {
      let selected = false;
      if (location.pathname === "/" && header.key === "home") {
        selected = true;
      }
      return (
        <div className={styles.dropdown}>
          <Link
            to={header.link}
            className={cx(styles.navigationText, {
              [styles.navigationTextSelected]:
                location.pathname.includes(header.key) || selected,
            })}
            key={header.key}
            onClick={() => setExpanded(false)}
          >
            {header.label}
            {renderDropdown(header)}
          </Link>
        </div>
      );
    });
  };

  const renderDropdown = (header) => {
    if (!header?.nesting && isEmpty(header.nesting)) {
      return null;
    }
    return (
      <Fragment>
        <div className={styles.dropdownContent}>
          {header.nesting.map((item) => {
            return <Link to={item.link}>{item.label}</Link>;
          })}
        </div>
      </Fragment>
    );
  };
  // const { symbol, lastSale } = data;
  // const prevCloseTsx = get(tsx, 'prices.values[0][2]');

  if (true) {
    return (
      <Navbar
        expand="lg"
        className={cx(styles.navBar, styles.container)}
        onToggle={() => setExpanded(!expanded)}
        expanded={expanded}
      >
        <Navbar.Brand href="/" className={styles.navBrand}>
        <img src={logo} alt="logo" className={styles.image} />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className={styles.toggler}
        />

        <Navbar.Collapse id="basic-navbar-nav" className={styles.collapse}>
          <div className={styles.stocksHeader}>
            <div className={styles.stocksHeaderText}>
            TSXV : HX
            </div>
            <div className={styles.socialIcons}>
              <a target="_blank" className={styles.link} href="https://www.facebook.com/HeliosXLithium"><FaFacebook size={25} style={{ marginRight: '10px' , color: '#3b3a45'}} /></a>
              <a target="_blank" className={styles.link} href="https://twitter.com/Heliosx_lithium"><FaTwitter size={25} style={{ marginRight: '10px', color: '#3b3a45' }} /></a>
              <a target="_blank" className={styles.link} href="https://www.linkedin.com/company/heliosxlithium/"><FaLinkedinIn size={25} style={{ marginRight: '10px', color: '#3b3a45' }} /></a>
           </div>
            <Link to="/login">
              <button className={styles.subscribe}>Login</button>
            </Link>
            {/* <span>{prevCloseTsx ? `TSX.V: ${prevCloseTsx} | `: ''} {symbol}: {lastSale}</span> */}
          </div>
          <div className={styles.headerNavigation}>
            {renderNavigation(navigation.data)}
          </div>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

Header.propTypes = {};

const mapStateToProps = (state) => ({
  header: state.header,
});

const mapDispatchToProps = {
  setHeaderData,
  setTsxData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
