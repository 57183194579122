import React from "react";
// import PropTypes from 'prop-types'

import styles from "./Content.module.css";

function Content(props) {
  const { children } = props;
  return <div className={styles.container}>{children}</div>;
}

Content.propTypes = {};

export default Content;
