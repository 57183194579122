import React from "react";

import styles from "./Responsibility.module.css";
function Responsibility(props) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Corporate Responsibility</div>
      <p className={styles.description}>
        HeliosX is an energy metals company that believes in
        responsibility, accountability and cooperation with all of our
        stakeholders. Our objective is to build and maintain prosperity for all
        stakeholders through responsible business practices.
      </p>
      <p className={styles.description}>
        HeliosX is committed to responsible business conduct,
        placing the utmost importance on respecting the safety and health of our
        employees, protecting the environment, and respecting the human rights
        of all people, especially our employees and the residents of the
        communities in which we operate.
      </p>
    </div>
  );
}

Responsibility.propTypes = {};

export default Responsibility;
