import React, { useEffect } from "react";
// import PropTypes from "prop-types";
import { FaRegFilePdf } from "react-icons/fa";
import { isEmpty } from "lodash-es";
import { connect } from "react-redux";

import { getInvestors } from "../../api/index";
import { setInvestors } from "../../Redux/Reducers/investors";

import investors1 from "../../assets/images/investors-1.jpg";
import investors2 from "../../assets/images/investors-2.jpg";
import investors3 from "../../assets/images/investors-3.jpg";

import styles from "./Investors.module.css";

function Investors(props) {
  const { investors, setInvestors } = props;
  useEffect(() => {
    if (isEmpty(investors.data)) {
      getInvestors().then((res) => {
        if (res.status === 200) {
          setInvestors(res.data.payload);
        }
      });
    }
  }, []);
  const data = investors.data;
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Information</div>

      <div className={styles.videoContainer}>


          <div className={styles.outerVideos}>
          <div className={styles.videos}>
            <div className={styles.innerVideo}>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/OjMwQAZZTKc?autoplay=0&?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <br></br>
            </div>
            <h4 className={styles.videoDescText}> Christopher Brown, CEO: Presentation to Pre-PEDAC, March 4, 2022.</h4>
          </div>
          </div>





        </div>
      {!isEmpty(data) &&
        Array.isArray(data) &&
        data.map((info) => {
          return (
            <div className={styles.pdfContainer}>
              <div className={styles.label}>{info.heading}</div>
              <a
                className={styles.link}
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_BACKEND_URL}/${(
                  info.fileUrl || ""
                ).slice(1)}`}
              >
                <FaRegFilePdf size={25} /> PDF
              </a>
            </div>
          );
        })}

      <div className={styles.heading}>
        Company Information
      </div>
      <div className={styles.tableContainer}>
        <table>
          <tr>
            <th>Trading Symbol</th>
            <th>Exchange</th>
            <th>Common Shares Issued</th>
            <th>Incentive Stock Options</th>
          </tr>
          <tr>
            <td>HX</td>
            <td>TSX Venture</td>
            <td rowSpan="3">36,181,804</td>
            <td rowSpan="3">825,000</td>
          </tr>
          <tr>
            <td>HXLTF</td>
            <td>OTC Markets</td>
          </tr>
          <tr>
            <td>C2U0</td>
            <td>Germany</td>
          </tr>
        </table>
      </div>

      <div className={styles.heading}>News Dissemination</div>
      <div className={styles.description}>
        HeliosX is committed to getting our company message out to
        the investment community. Our goal is to inform shareholders on a timely
        basis of corporate developments as we move forward, as well as to inform
        readers of about developments of senior Lithium producers and end users
        around the world. HeliosX wants to keep readers up-to-date on events that
        will materially affect the price movement of various forms of lithium.
        <br />
        <br />
        HeliosX is committed to tell our story and has retained various channels
        of distribution including the service of TheNewswire, Benchmark Mineral
        Intelligence and Lithium Investing News to disseminate our company’s
        news.
      </div>
      <div className={styles.news}>
        <div className={styles.newsContainer}>
          <img className={styles.img} src={investors1} alt="investors" />
          <div className={styles.text}>
            We are part of the Investing News Network. We bring unbiased,
            independent news and information, specifically for investors, miners
            and end users interested in Lithium. Our mission is to become the
            number one source of independent, unbiased news and education
            helping investors realize their financial goals. To be
            internationally respected for our integrity, our people and our
            commitment to excellence. And to create an environment where
            sophisticated investors can understand and connect directly with
            trusted organizations and businesses.
          </div>
          <div className={styles.readMoreContainer}>
            <a
              className={styles.readMore}
              href="https://investingnews.com/company-pro/files/lithium-exploration-argentina-nevada-dajin-resources/"
              target="_blank"
              rel="noreferrer"
            >
              Read More &gt;
            </a>
          </div>
        </div>
        <div className={styles.newsContainer}>
          <img className={styles.img} src={investors2} alt="investors" />
          <div className={styles.text}>
            TheNewswire is a timely disclosure press release disseminator for
            TSX Venture, CNSX and OTC:BB- listed public companies, providing
            real time distribution to all investment dealer / broker terminals,
            including Thomson Reuters , Dow Jones, Morningstar and Quotemedia
            Newswire. Additionally, TNW provides direct, simultaneous
            dissemination to Globeinvestor, Market News Publishing, Accesswire,
            StockHouse, Stockwatch, the entire Comtex and Newstex.com news
            networks, Acquiremedia, plus an array of ancillary recipient
            financial news portals.
          </div>
          <div className={styles.readMoreContainer}>
            <a
              className={styles.readMore}
              href="https://www.thenewswire.ca/"
              target="_blank"
              rel="noreferrer"
            >
              Read More &gt;
            </a>
          </div>
        </div>
        <div className={styles.newsContainer}>
          <img className={styles.img} src={investors3} alt="investors" />
          <div className={styles.text}>
            Specially engineered raw materials such as graphite, lithium, cobalt
            and vanadium are irreplaceable components in some of the world’s
            most important technologies. And in an increasingly connected world
            where information is readily available, misinformation and bad data
            are prevalent. Benchmark’s goal is to become the world’s most
            trusted source of information in this space by offering Accurate
            market prices collected and set by us for the industry, original,
            accurate supply and demand data, impartial, independent, expert
            analysis, and forecasting of supply, demand and prices.
          </div>
          <div className={styles.readMoreContainer}>
            <a
              className={styles.readMore}
              href="https://www.benchmarkminerals.com/"
              target="_blank"
              rel="noreferrer"
            >
              Read More &gt;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

Investors.propTypes = {};

const mapStateToProps = (state) => ({
  investors: state.investors,
});

const mapDispatchToProps = {
  setInvestors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Investors);
