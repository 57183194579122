import React from "react";
// import PropTypes from "prop-types";
import cx from 'classnames';

import teelsMarsh from "../../assets/projects/teels-marsh.png";
import alkaliLake from "../../assets/projects/alkali-lake.png";
import salinasGrandes from "../../assets/projects/guayatoyoc.png";

import styles from "./Projects.module.css";

function Projects(props) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Projects</div>
      All project entities, reports and intellectual property are wholly owned by HeliosX Lithium & Technologies Corp. as of the Shareholder’s unanimous approval of the Plan of Arrangement between Dajin Lithium Corp. and HeliosX Lithium Technologies Corp on November 23, 2021. 
      <div className={styles.heading}>
        Teels Marsh valley, Mineral County, Nevada, USA
      </div>
      <div className={styles.projectContainer}>
        <img className={styles.img} src={teelsMarsh} alt="teels-marsh" />
        <div className={styles.description}>
          Dajin Lithium (US) Corp. has 100% ownership of 403 placer claims
          covering 7,914 acres (3,202 hectares) of the Teels Marsh area. This
          area of the Teels Marsh was extensively prospected by the well-known
          prospector, “Borax” Smith. In 1867, Smith began processing borax and
          salt deposits. This property eventually became part of the early
          history of the giant borax producer, U.S. Borax Inc., a wholly owned
          subsidiary of one of the world’s largest mining companies, Rio Tinto
          PLC.
          <br />
          With the recognition of Lithium and Boron in the Teels Marsh brines,
          it was concluded that these brines were formed by the evaporation of
          water leaching from volcanic ash, originating from the the super
          eruption of nearby Long Valley Caldera, California. This is the same
          ash fall environment that contributed to the Lithium brines of the
          nearby Clayton Valley/ Silver Peak operations of Albemarle
          Corporation, which is the sole Lithium producer in the USA. Historical
          reporting of the Teels Marsh property, by the US Geological Survey
          (OFR: 76-567) indicates Lithium concentrations in the Teels Marsh
          playa brines of up to 850ppm. A report by the Clay Minerals society in
          1981 analyzed 17 interstitial brine samples at Teels Marsh, which
          contained concentrations of Boron ranging from 214 to 893ppm.
        </div>
      </div>

      <div className={styles.heading}>
        Alkali Lake Valley, Esmeralda County, Nevada, USA
      </div>
      <div className={styles.projectContainer}>
        <img className={styles.imgRight} src={alkaliLake} alt="alkali-lake" />
        <div className={styles.description}>
          Dajin Lithium (US) Corp. has 100% interest in 62 placer mineral claims
          covering 1,240 acres in the Alkali Lake valley, in Esmeralda County,
          Nevada. Dajin has entered into an Earn-in Agreement with Lone Mountain
          Resources, LLC (“LMR”), a privately held US Lithium exploration and
          development company affiliated with Lilac Solutions, Inc. Lilac is a
          well financed Lithium extraction technology company that LMR
          anticipates will provide technology and services to advance the
          project.
          <br />
          For LMR to earn a 75% ownership interest in Dajin’s Alkali Lake
          Lithium project LMR has paid US $25,000 and agreed to make project
          related expenditures of at least US $200,000 prior to August 31, 2022.
          An initial work plan consisting of surface sampling and geoscience
          work has commenced to define the optimal location for an exploration
          well expected to be drilled to a depth of 1,500 feet to assess rock
          and brine properties. The Agreement encompasses a 3-mile area of
          interest around the current claims. Upon satisfying the terms of the
          Agreement, Dajin may enter into a joint venture mining agreement or
          retain a royalty interest of 3% of the value of all minerals extracted
          and sold by LMR. LMR shall have the right to purchase the 3% royalty
          from Dajin for US $1,500,000.
          <br />
          The Alkali Lake valley Lithium property is located approximately 7 km
          northeast of Albemarle's Silver Peak Lithium Brine mine, operation,
          the only producing brine based Lithium mine in operation in North
          America. Like Clayton Valley, Alkali Lake valley is a classic, fault
          bounded closed basin. There is an active geothermal system, Alkali Hot
          Spring along the southern margin of the basin. Just as with
          circulating basin waters, deep fluids, particularly hot ones
          associated with the geothermal resource, dissolve rocks, liberating
          Boron, Lithium and other elements such as sodium and chloride. These
          minerals are brought to the surface as springs emanating from the
          faults bounding the playa. The association of a closed basin with an
          arid environment, a geothermal system and plentiful sources of Lithium
          suggests that Alkali Lake valley may host significant Lithium rich
          brines.
        </div>
      </div>

      <div className={styles.heading}>
        Guayatoyoc, Jujuy Province, Northwestern Argentina
      </div>
      <div className={styles.projectContainer}>
        <img className={cx(styles.img, styles.imgHeight)} src={salinasGrandes} alt="guayatoyoc" />
        <div className={styles.description}>
          Dajin Resources S.A. owns a large land position in northwestern
          Argentina, part of an area known as the “Lithium Triangle” where it
          has been reported that 80% of the world’s supply of Lithium is found
          in flat salt lake basins, also known as “salars” or “brines.” On
          August 8, 2016, Dajin's wholly owned Argentinian subsidiary, Dajin
          Resources S. A., signed a binding Memorandum of Understanding ("MOU")
          with LSC Lithium Corporation ("LSC") whereby LSC was granted an option
          to earn a 51% interest in Dajin's Lithium properties in Argentina.
          Lithium brine bodies in salt lakes, or salars, are formed in basins
          where water which has leached the Lithium from the surrounding rock is
          trapped and concentrated by evaporation. The process of extracting the
          Lithium from the brines involves pumping the brine into a series of
          evaporation ponds to crystallize other salts, leaving a Lithium-rich
          brine solution. This brine solution is further processed to remove
          impurities before conversion to either Lithium carbonate or Lithium
          chloride for further upgrading to Lithium hydroxide. The majority of
          Dajin’s concessions are on the Salinas Grandes/Guayatayoc salar.
          Seismic interpretations suggest these salars may be as much as 800m
          thick.
          <br />
          Dajin’s concessions are contiguous with a number of concessions owned
          by Orocobre Limited (TSX:ORL-T) which announced in 2010 that
          preliminary sampling on the Salinas Grandes indicated highly
          concentrated deposits of Lithium and potassium over an area of
          approximately 60 square kilometres. A subsequent 12 hole diamond drill
          program and 47 holes of shallow auger drilling lead to an estimated
          inferred resource of 56.5 million cubic metres of brine at 795
          milligrams per litre Lithium and 9,550 milligrams per litre potassium,
          which is equivalent to 239,200 tonnes of Lithium carbonate and 1.03
          million tonnes of potash based on 5.32 tonnes of Lithium carbonate
          being equivalent to one tonne of Lithium and 1.91 tonnes of potash
          being equivalent to one tonne of potassium. Dajin believes that a
          drill program on its concessions could produce similar results as
          Orocobre and ultimately lead to delineating its own resource. Also
          active in Jujuy province, and adjacent to Orocobre’s Olaroz Lithium
          project, is Lithium Americas Corp. (TSX:LAC-T) which has commenced
          construction of its Cauchari-Olaroz project.
          <br />
          Nearly one-half of the world’s Lithium production comes from the
          Lithium brines in the Andes mountains region that encompasses parts of
          Argentina and Chile. This area, commonly referred to as the “Lithium
          Triangle,” includes the Salinas Grandes salar, which is the site of
          Dajin’s extensive land package. In the mid-1990s, the development of
          these large-scale, low-cost brine resources in Chile and Argentina by
          Sociedad Quimica y Minera S.A. (NYSE: SQM), Rockwood Lithium Inc.
          (NYSE: ROC) and FMC Lithium (NYSE: FMC) fundamentally changed global
          Lithium supply. With its cost advantage of extracting Lithium brines
          over hard rock, mineral-based production brine producers have lowered
          prices to gain market share. In March 2019, Pluspetrol Resources
          Corporation B.V. acquired all of the issued and outstanding shares of
          LSC Lithium Corporation for approximately CDN $111 million. LSC
          Lithium held an agreement to acquire a 51% interest in Dajin Resources
          S.A with the exploration expenditure of CDN $2,000,000 on the Salinas
          Grandes salar. In October 2019, Pluspetrol earned a 51% interest in
          Dajin Resources S.A. with the expenditure of US$1,250,000 on the
          Salinas Grandes salar and payment of US$600,000 to HeliosX.
        </div>
      </div>
    </div>
  );
}

Projects.propTypes = {};

export default Projects;
