/* eslint-disable react/jsx-no-target-blank */
import React, { useRef } from "react";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash-es";

import lithium1 from "../../assets/lithium/lithium-1.jpg";
import lithium2 from "../../assets/lithium/lithium-2.jpg";
import lithium3 from "../../assets/lithium/lithium-3.jpg";
import lithium4 from "../../assets/lithium/lithium-4.jpg";
import lithium5 from "../../assets/lithium/lithium-5.jpg";
import lithium6 from "../../assets/lithium/lithium-6.jpg";
import lithium7 from "../../assets/lithium/lithium-7.jpg";
import lithium8 from "../../assets/lithium/lithium-8.jpg";
import lithium9 from "../../assets/lithium/lithium-9.jpg";
import lithium10 from "../../assets/lithium/lithium-10.jpg";
import lithium11 from "../../assets/lithium/lithium-11.jpg";
import lithium12 from "../../assets/lithium/lithium-12.jpg";
import lithium13 from "../../assets/lithium/lithium-13.jpg";
import lithium14 from "../../assets/lithium/lithium-14.jpg";
import lithium15 from "../../assets/lithium/lithium-15.jpg";
import lithium16 from "../../assets/lithium/lithium-16.jpg";
import lithium17 from "../../assets/lithium/lithium-17.jpg";
import lithium18 from "../../assets/lithium/lithium-18.jpg";
import lithium19 from "../../assets/lithium/lithium-19.jpg";
import lithium20 from "../../assets/lithium/lithium-20.jpg";
import lithium21 from "../../assets/lithium/lithium-21.jpg";

import styles from "./Lithium.module.css";

function Lithium(props) {
  const { location } = props;
  const market = useRef(null);
  const sD = useRef(null);
  const products = useRef(null);
  const reserves = useRef(null);
  const articles = useRef(null);

  const handleScroll = (ref) => {
    if (!isEmpty(ref.current)) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  switch (location.pathname) {
    case "/lithium/market":
      handleScroll(market);
      break;
    case "/lithium/supply-demand":
      handleScroll(sD);
      break;
    case "/lithium/products":
      handleScroll(products);
      break;
    case "/lithium/reserves":
      handleScroll(reserves);
      break;
    case "/lithium/articles":
      handleScroll(articles);
      break;
    default:
      console.log("nothing to scroll to");
  }

  return (
    <div className={styles.container}>
      <div ref={market} className={styles.heading}>
        The Lithium Market
      </div>
      <div className={styles.subHeading}>What is Lithium?</div>
      <div className={styles.description}>
        Lithium (chemical symbol: Li) is the lightest of all metals. It does not
        occur as a pure element in nature but is contained within mineral
        deposits or salts, including brine lakes and seawater. The contained
        concentration of lithium is generally low and there are only a limited
        number of resources where lithium can be economically extracted.
      </div>
      <div
        style={{
          height: "140px",
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "150px",
          }}
          className={styles.img}
          src={lithium1}
          alt="lithium"
        />
      </div>
      <div className={styles.description}>
        Lithium and its chemical compounds exhibit a broad range of beneficial
        properties including:
      </div>
      <div className={styles.description}>
        <ul>
          <li>The highest electrochemical potential of all metals.</li>
          <li>An extremely high co-efficient of thermal expansion.</li>
          <li>Fluxing and catalytic characteristics.</li>
          <li>Acts as a viscosity modifier in glass applications.</li>
        </ul>
      </div>

      <div className={styles.heading}>Chemical Applications</div>
      <div className={styles.description}>
        Lithium can be processed to form a variety of chemicals including
        lithium carbonate, lithium bromide, lithium chloride, butyl lithium and
        lithium hydroxide. The fastest growing and second largest market for
        lithium globally is for use in batteries.
      </div>
      <div
        style={{
          height: "80px",
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "150px",
          }}
          className={styles.img}
          src={lithium2}
          alt="lithium"
        />
      </div>
      <div className={styles.imgSubtitle}>
        Typical non-rechargeable batteries found in items like remote controls
        and children's toys.
      </div>
      <div className={styles.description}>
        There are two types of lithium batteries,
        <br />
        <strong>Primary (non-Rechargeable):</strong> Including coin or
        cylindrical batteries used in calculators and digital cameras. Lithium
        batteries have higher energy density compared to alkaline batteries, as
        well as lower weight and long shelf and operating life.
      </div>
      <br />
      <div className={styles.description}>
        <strong>Secondary (rechargeable):</strong>
        Key current applications for lithium batteries are in powering cell
        phones, laptops and other hand held electronic devices, power tools and
        large format batteries for electricity grid stabilisation. The advantage
        of the lithium secondary battery are its higher energy density and
        lighter weight, compared to nickel cadmium and nickel metal hydride
        batteries.
        <br />
        <br />A growing application for lithium batteries is a power source for
        a wide range of electric vehicles, including electric bikes, scooters,
        buses, taxies, and passenger electric vehicles. There are three main
        categories of electric passenger vehicles; Hybrid Electric Vehicles,
        Plug-In Hybrid Vehicles and Electric Vehicles. The electrification of
        vehicles is strongly supported by governments around the world due to
        increasing political and consumer focus on climate change and energy
        security. The introduction of mass produced passenger electric vehicles
        has the potential to significantly increase the future consumption of
        lithium.
      </div>

      <div
        style={{
          height: "130px",
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "180px",
          }}
          className={styles.img}
          src={lithium3}
          alt="lithium"
        />
      </div>
      <div className={styles.imgSubtitle}>
        Typical non-rechargeable batteries found in items like remote controls
        and children's toys.
      </div>

      <div className={styles.heading}>Other Chemical Applications:</div>
      <div className={styles.subHeading}>
        Lithium chemicals are also used in a variety of other applications
        including:
      </div>
      <div className={styles.description}>
        <ul>
          <li>
            Lubricants: Lithium is used as a thickener in grease, ensuring
            lubrication properties are maintained over a broad range of
            temperature.
          </li>
          <li>
            Aluminum Smelting: the addition of lithium during aluminum smelting
            reduces power consumption, Source USGS increases both electrical
            conductivity and reduces fluorine emission. The process increases
            strength and lightens the end product. These new aluminum/lithium
            alloys are now being used in the auto and airline industry for
            fossil fuel savings.
          </li>
          <li>
            Air Treatment: Lithium may be used as an absorption medium for
            industrial refrigeration systems and for humidity control and drying
            systems.
          </li>
          <li>
            Pharmaceuticals: Lithium is used in the treatment for bi-polar
            disorder as well as in other pharmaceutical products.
          </li>
        </ul>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img className={styles.img} src={lithium4} alt="lithium" />
      </div>
      <div className={styles.heading}>Glass and Ceramics:</div>
      <div className={styles.description}>
        <strong>Glass:</strong> including container glass, flat glass,
        pharmaceutical glass, specialty glass and fiberglass. These glass
        products may be designed for durability or corrosion resistance or for
        the use at high temperatures where thermal shock resistance is
        important. The addition of lithium increases the glass melt rate, lowers
        viscosity and the melt temperature providing higher output energy
        savings and moulding benefits.
        <br />
        <br />
        <strong>Ceramics:</strong> including ceramic bodies, frits glazes and
        heatproof ceramic cookware. Lithium lowers firing temperatures and
        thermal expansion and increases the strength of ceramic bodies. The
        addition of lithium to glazes improves viscosity for coating, as well as
        improving the glazes colour, strength and lustre.
        <br />
        <br />
        <strong>Specialty Applications:</strong> including induction cook tops
        and cookware. Lithium's extremely high co-efficient of thermal expansion
        makes these products resistant to thermal shock and imparts mechanical
        strength.
      </div>

      <div className={styles.heading}>Other Technical Applications:</div>
      <div className={styles.description}>
        Lithium is also used in a variety of metallurgical applications,
        including:
        <br />
        <strong>Steel Castings: </strong> the addition of lithium to continuous
        castings moulds fluxes assists in producing thermal insulation and
        lubricates the surface of the steel in the continuous casting process.
        <br />
        <br />
        <strong>Iron Castings:</strong> in the production of iron castings, such
        as engine blocks, lithium reduces the effect of veining, thereby
        reducing the number of defective casts
        <br />
        <br />
      </div>
      <div ref={sD} className={styles.heading}>
        Lithium Supply and Demand
      </div>
      <div className={styles.subHeading}>
        The following narrative is drawn from the United States Geological
        Survey(USGS)
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "100px",
          }}
          className={styles.img}
          src={lithium5}
          alt="lithium"
        />
      </div>
      <div className={styles.description}>
        In 2012, lithium consumption in the United States was estimated to be
        about 2,000 metric tons (t) of contained lithium, the same as that in
        2011 and about 80% greater than that in 2010. The increase in U.S.
        consumption in 2011 and 2012 relative to 2010 was primarily the result
        of increased demand for lithium- based air treatment, battery, ceramic
        and glass, grease, metallurgical, pharmaceutical, and polymer products
        in the United States. Net imports of lithium compounds into the United
        States in 2012 decreased by about 5% from those in 2011, and were nearly
        15% lower than those in 2008. In the United States, one lithium brine
        operation with associated lithium carbonate and lithium hydroxide plants
        operated in Silver Peak, NV. Domestic and imported lithium carbonate,
        lithium chloride, and lithium hydroxide were consumed directly in
        industrial applications and used as raw materials for downstream lithium
        compounds. Argentina and Chile were the principal sources of imported
        lithium carbonate, lithium chloride, and lithium hydroxide. World
        lithium production in 2012 (excluding U.S. production) was estimated to
        be 36,600t of lithium contained in minerals and compounds, an increase
        of 8% from that of 2011.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img className={styles.img} src={lithium6} alt="lithium" />
      </div>
      <div className={styles.description}>
        Lithium historically has been mined from two distinct sources:
        continental brines and hard rock minerals. Chile, the world's leading
        producer of lithium carbonate, recovered lithium from two brine
        operations on the Salar de Atacama in the Andes. Concentrated brines
        were transported to and processed at two lithium carbonate plants, one
        lithium chloride plant, and one lithium hydroxide plant in Antofagasta,
        on the Chilean coast. Lithium carbonate and lithium chloride also were
        produced from brines from the Salar del Hombre Muerto in the Andes
        Mountains in Argentina. A large percentage of the lithium carbonate
        produced in South America was exported to the United States. In China,
        lithium carbonate was produced from brines from the Zabayu Salt Lake in
        western Tibet and from the Dongtai and Xitai Salt Lakes in Qinghai
        Province. China was the only country that produced large quantities of
        lithium carbonate from mineral concentrates, mostly from imported
        Australian spodumene. Australia was, by far, the leading producer of
        lithium mineral concentrates, but Brazil, China, Portugal, and Zimbabwe
        also produced significant quantities, most of which is used directly in
        the production of ceramics and glass
      </div>
      <br />
      <div className={styles.heading}>Consumption</div>
      <div className={styles.description}>
        Lithium is sold as brines, compounds, metal, or mineral concentrates
        depending on the end use. Lithium's electrochemical reactivity and other
        unique properties have resulted in many commercial lithium products. For
        many years, most lithium compounds and minerals were used in the
        production of ceramics, glass, and primary aluminum. As a result of
        growth in lithium battery use and a reduction in the use of lithium in
        aluminum production, batteries have gained market share. For 2012,
        according to one estimate, the main global markets for lithium products
        were batteries, 35%; ceramics and glass, 26%; lubricating greases, 13%;
        metallurgy, 8%; air conditioning, 4%; polymers, 3%; medical
        applications, 3%; and other uses, 8%* (Desormeaux, 2013, p. 13). Other
        uses may have included alloys, cement and concrete, dyestuffs,
        industrial bleaching and sanitation, pool chemicals, and specialty
        inorganics (FMC Corp., 2008).
      </div>
      <div className={styles.subHeading}>
        * U.S. consumption was not available in this report.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{ width: "100%" }}
          className={styles.img}
          src={lithium7}
          alt="lithium"
        />
      </div>
      <div className={styles.imgSubtitle}>Source: signumBOX estimates 2012</div>
      <div className={styles.description}>
        Lithium's properties make it one of the most attractive battery
        materials of all the elements. Worldwide, rechargeable lithium batteries
        powered most cellular telephones and laptop computers, as well as many
        heavy-duty power tools. Automakers were developing and Aerovironnment
        Electric Vehicle charging station improving lithium batteries for
        electric vehicles (EVs), HEVs, and plug-in hybrid electric vehicles
        (PHEVs).
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "200px",
          }}
          className={styles.img}
          src={lithium8}
          alt="lithium"
        />
      </div>
      <div className={styles.imgSubtitle}>
        Aerovironnment Electric Vehicle charging station
      </div>

      <div className={styles.description}>
        The Portable Rechargeable Battery Association reported that the number
        of lithium-ion cells produced worldwide increased to 4.4 billion in
        2012, a 450% increase from 800 million cells made in 2002 (Seetharaman,
        2013). The global lithium-ion battery market had revenues of $11.7
        billion in 2012. Consumer applications (cameras, cellular telephones,
        computer tablets, laptop computers, etc.) accounted for 64% of the
        revenues; industrial applications (aerospace and defense, energy
        storage, industrial tools, medical devices, etc.) accounted for 22%; and
        automotive applications (EVs, HEVs, PHEVs, and electric bicycles)
        accounted for 14%. Although the consumer segment accounted for the
        highest revenues in 2012, the industrial and automotive segments
        increased at a faster pace. The greatest share of consumer and
        industrial revenues came from North America, and China led in automotive
        revenues. (Tohani, 2013).
        <br />
        <br />
        Increased use of larger lithium-ion batteries can be attributed in part
        to the rapid replacement of nickel-cadmium batteries with lithium-ion
        batteries in heavy-duty power tools. According to Robert Bosch GmbH, a
        leading manufacturer of power tools, 74% of new cordless power tools
        produced in 2012 were powered by lithium-ion batteries, up from only 26%
        in 2007 (Müller, 2013).
        <br />
        <br />
        In 2012, China, Japan, and the Republic of Korea accounted for 85% to
        90% of global lithium-ion battery production. Germany, Switzerland, and
        the United States were among other countries producing lithium-ion
        batteries (Tohani, 2013). According to a Japanese market research firm,
        the Republic of Korea overtook Japan (the leading lithium-ion battery
        producer since 1991) in 2011 to become the leading manufacturer of
        lithium-ion batteries (Sung-Mi, 2012).
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "200px",
          }}
          className={styles.img}
          src={lithium9}
          alt="lithium"
        />
      </div>
      <div className={styles.description}>
        In 2011, Alcoa Inc. developed a third- generation aluminum- lithium
        alloy that could contribute to lighter, less costly, and more corrosion
        resistant airplanes compared with those using composite alternatives.
        The alloy reduced density by up to 7% in critical structural components.
        In May, as part of a plan to expand its aluminum lithium production
        capacity and capabilities, Alcoa began construction of a new $90 million
        facility adjacent to its Lafayette, IN, plant with a capacity to produce
        more than 20,000 t/yr of aluminum lithium. Alcoa also began expanding
        aluminum lithium capacity at its Technical Center in Alcoa Center, PA,
        and Alcoa's Kitts Green plant in the United Kingdom (Chai, 2011; Alcoa
        Inc., 2013, p. 17).
        <br />
        <br />
        Addition of lithium carbonate to aluminum potlines lowers the melting
        point of the cryolite bath, allows a lower operating temperature for the
        cells, increases the electrical conductivity, and decreases bath
        viscosity. U.S. primary aluminum production and U.S. lithium consumption
        for aluminum production in 2012 remained near the level in 2011.
        <br />
        <br />
        "Must-Know": What makes Alcoa bullish on aluminum demand?
        <br />
        <a
          href="https://finance.yahoo.com/news/must-know-makes-alcoa-bullish-130043815.html"
          target="_blank"
        >
          Read More
        </a>
      </div>

      <div className={styles.heading}>World Review</div>
      <div className={styles.description}>
        Worldwide production and consumption of lithium increased in 2012. Two
        major lithium producing countries, Chile and Australia, increased
        lithium production by 9% and 8%, respectively, from that of 2011. World
        lithium production in 2012 (excluding U.S. production) was estimated to
        be 36,600t of lithium contained in minerals and compounds, an increase
        of 8% from that of 2011.
        <br />
        <br />
        Lithium end-use markets for batteries, ceramics and glass, grease, and
        other industrial applications all increased. World lithium consumption
        was estimated by Roskill Information Services (2013a) to be 28,200t of
        lithium contained in minerals and compounds in 2012, an increase of
        approximately 9% from Roskill's consumption estimate of 2011. Capacity
        utilization among the current lithium producers was estimated to be
        greater than 80%.
        <br />
        <br />
        Total lithium consumption growth averaged 6.4% per year from 2000
        through 2012. Based on estimated total lithium consumption and an
        estimated lithium chemical market share of total consumption of about
        75%, an estimated 21,200 t of lithium was consumed in chemicals and the
        remainder as mineral concentrates used in the ceramics and glass
        industry in 2012.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "800px",
          }}
          className={styles.img}
          src={lithium10}
          alt="lithium"
        />
      </div>
      <div className={styles.heading}>Lithium Outlook</div>
      <div className={styles.description}>
        World lithium consumption forecasts to 2015 and 2020 were projected by
        numerous lithium producers and many leading lithium market analysts. On
        average, their conclusions indicated that world lithium consumption was
        likely to increase to approximately 190,000 t/yr of LCE by 2015 and to
        280,000 t/yr of LCE by 2020 (Clarke, 2012a). From 2012 to 2017, average
        annual growth in world lithium consumption was expected to be
        approximately 11% (Baylis, 2013, p. 23). New lithium producers were
        expected to supply approximately 25% of the lithium required by 2020
        (Baylis and Chegwidden, 2012, p. 29).
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "200px",
          }}
          className={styles.img}
          src={lithium11}
          alt="lithium"
        />
      </div>
      <div className={styles.imgSubtitle}>Tesla Model - X</div>
      <div className={styles.description}>
        Annual growth in lithium consumed globally for batteries averaged 21%
        per year between 2000 and 2012. Demand for rechargeable lithium-ion
        batteries continues to have the greatest potential for growth. The
        lithium-ion battery market was expected to increase by 200% by 2017, the
        equivalent of an additional 90,000 t of LCE (Baylis, 2013, p. 26). The
        global market for lithium-ion batteries was forecast to reach $9 billion
        by 2015, with potential to exceed $50 billion by 2020 (Schrader, 2012).
        Other lithium end uses were projected to increase also, but at lower
        rates than batteries.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "200px",
          }}
          className={styles.img}
          src={lithium12}
          alt="lithium"
        />
      </div>
      <div className={styles.imgSubtitle}>Electric Harley Davidson</div>
      <div className={styles.description}>
        Most global automobile manufacturers have announced plans to use
        lithium-ion batteries in current or future generations of EVs, HEVs, and
        PHEVs. Vehicles with lithium-ion batteries from companies such as
        Bavarian Motor Works AG (BMW); Ford Motor Co.; General Motors Co.; Honda
        Motor Co., Ltd.; Hyundai Group; Tesla Motors, Inc. Toyota Motor Corp.;
        and Volkswagen Group were introduced in 2012. Major automobile
        manufacturers formed partnerships with established battery manufacturers
        to build battery plants for hybrid and electric-vehicles and begin mass
        production of lithium-ion batteries.
        <br />
        <br />
        The worldwide market for lithium-ion batteries used in light duty
        vehicles was forecast to increase to approximately $22 billion by 2020
        from $1.6 billion in 2012. As manufacturing efficiencies improve, the
        cost of lithium- ion batteries was expected to decrease by more than
        one-third by 2017 (Pike Research, 2012; Navigant Research, 2013).
        <br />
        <br />
        The successful use of lithium-ion batteries in EVs, HEVs, and PHEVs
        could greatly increase consumption of lithium. If the rate of
        consumption increases faster than supply, prices could increase, and
        spodumene and other lithium resources that had been considered
        uneconomic might once again yield economically feasible raw materials
        for the production of lithium carbonate. New lithium mineral operations
        under development throughout the world specifically designed to produce
        battery-grade lithium carbonate demonstrate the potential economic
        viability of these mineral operations.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "200px",
          }}
          className={styles.img}
          src={lithium13}
          alt="lithium"
        />
      </div>
      <div className={styles.imgSubtitle}>
        Typical US wind farm that can store electric energy using green energy
        lithium storage solutions.
      </div>
      <div className={styles.description}>
        Various countries worldwide were establishing national alternative
        energy policies that have the potential to substantially increase
        lithium demand. It was anticipated that Asia, North America, and Western
        Europe would be at the forefront of adopting utility-scale energy
        storage systems that would become integral components of electricity
        grids in the future, for long-duration storage as well as short-duration
        ancillary services. China, in particular, was expected to become the
        largest utility-scale energy storage market in the world, with $586
        billion in Government funds to be invested by 2020.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "200px",
          }}
          className={styles.img}
          src={lithium14}
          alt="lithium"
        />
      </div>
      <div className={styles.imgSubtitle}>Tesla Lithium Car Battery</div>
      <div className={styles.description}>
        These energy storage systems could be the beneficiaries of the
        widespread research and development of lithium-ion batteries for the
        transportation sector. Of several energy storage technologies competing
        within the short-duration ancillary services market, advanced
        lithium-ion batteries were expected to hold the greatest potential,
        capturing approximately 70% of the ancillary services market worldwide
        by 2019. Worldwide revenue from sales of lithium-ion batteries for use
        in utility-scale energy storage systems was expected to increase to
        nearly $6 billion in 2020, from $72 million in 2012 (Pike Research,
        2010; Oyama, 2011).
        <br />
        <br />
        In addition to energy storage systems, several other potential growth
        areas could significantly increase lithium demand by 2020. Lithium used
        in solar power applications and in nuclear reactor applications offer
        substantial opportunities for future lithium growth.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "200px",
          }}
          className={styles.img}
          src={lithium15}
          alt="lithium"
        />
      </div>
      <div className={styles.imgSubtitle}>
        Solar panels that use lithium solutions to store the energy they create.
      </div>
      <div className={styles.description}>
        For further information we recommend reading the entire U.S.G.S Report
        at:
      </div>
      <br />
      <a
        href="https://www.rmi.org/Content/Images/Lithium%20ion.PDF"
        target="_blank"
      >
        Read More
      </a>
      <div className={styles.heading}>Words from Industry Leaders</div>
      <div className={styles.subHeading}>SQM media release April 4th 2014</div>
      <div className={styles.description}>
        We believe the lithium market is positioned to grow in the short and
        long term, resulting from the development of new technologies as well as
        due to strong growth in industrial applications. Lithium market demand
        should grow between 8-10% in 2014
      </div>
      <div className={styles.subHeading}>
        Tesla Credit Suisse Equity Research American Region
      </div>
      <div className={styles.subHeading}>Specialty Chemicals May 27 2014</div>
      <div className={styles.description}>
        Nevada selected as official site for Tesla battery gigafactory. Tesla is
        also pushing ahead for a gigafactory with 35gwh of production that would
        equate to 25K tones of lithium carbonate equated demand
      </div>
      <div className={styles.subHeading}>Tesla Motors Inc - Elon Musk</div>
      <div className={styles.description}>
        The need for lower-cost batteries for autos and power storage means
        there will need to be hundreds of "gigafactories" like the one Tesla is
        planning to build
      </div>
      <div className={styles.subHeading}>
        Credit Suisse Research May 27th 2014
      </div>
      <div className={styles.description}>
        Bottom line, demand growth is projected to grow 12% annually compounded,
        looking out to the end of the decade. While there are a number of
        capacity projects planned to come up during this time frame, we believe
        a number of them will be either delayed or potentially even cancelled,
        suggesting a noticeable spread between theoretical capacity and
        effective capacity. Should this scenario play out, lithium pricing
        should see upward pressure looking forward with certain industry checks
        suggesting 2020 prices could be 40 -60% higher than today.
      </div>
      <div className={styles.subHeading}>FMC Minerals - 2013 Annual Report</div>
      <div className={styles.description}>
        The lithium business started the year slowly. But by late third quarter,
        FMC completed operations improvements at its Argentina Salow facility
        that increased annualized capacity by over 30% from pre-expansion
        levels. FMC anticipates a rise in lithium demand of approximately 10%
        annually throughout 2020, driven mainly by energy storage for batteries
        used in hybrid and electric vehicles.
      </div>
      <div className={styles.subHeading}>
        Rockwood March 3rd 2014 Conference Call
      </div>
      <div className={styles.description}>
        I think that, worldwide, the demand for lithium products will probably
        grow by 15% to 20%. The issue for Rockwood is going to be, can we
        actually supply it? Because we are getting close to being sold out, and
        that's why we want to bring the new plant on stream, but that plant is
        not going to come on-stream until 2015.
      </div>
      <div className={styles.subHeading}>
        FMC Investorsb - Marketing Dec 2013
      </div>
      <div className={styles.description}>
        Lithium demand growth of 9% CAGR expected through 2020.
      </div>
      <div className={styles.subHeading}>Liz Jarvis-Shean, Tesla Motors</div>
      <div className={styles.description}>
        Tesla Motors Inc. (TSLA), the electric vehicle maker co-founded by Elon
        Musk, plans to use only raw materials sourced in North America for its
        proposed $5 billion U.S. battery factory. The Silicon Valley Company
        won't look overseas for the graphite, cobalt and other materials needed
        for its so-called Gigafactory. "It will enable us to establish a supply
        chain that is local and focused on minimizing environmental impact while
        significantly reducing battery cost."
      </div>
      <div ref={products} className={styles.heading}>
        Aluminum-Lithium Alloy
      </div>
      <div className={styles.subHeading}>
        Making transportation Lighter and Greener Using Aluminum-Lithium Alloy
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "200px",
          }}
          className={styles.img}
          src={lithium16}
          alt="lithium"
        />
      </div>
      <div className={styles.description}>
        Aircraft manufacturers are continuously seeking to refresh existing
        aircrafts or develop next generation aircrafts, their customers want
        planes that are less expensive to fly, have longer inspection intervals,
        longer useful lifetimes and offer enhanced comfort for the flying
        public. Recently, Alcoa Inc. has partnered with aircraft manufacturers
        to meet this market need. Alcoa has developed new aluminum alloys and
        third generation aluminum-lithium alloys that are up to 7% less dense
        than the current generation. These new aluminum-lithium alloys are
        expected to reduce the next generation, short range aircraft by up to
        10% versus composite-intensive planes. This weigh reduction is expected
        to produce a 12% increase in fuel efficiency. Alcoa is planning to
        expand their aluminum-lithium production facilities at three locations
        around the world.
        <br />
        <br />
        "The future of aviation is being built with aluminum-lithium and Alcoa
        is making big moves to capture that demand" Klaws Klein, Alcoa Chairman
        and CEO. "This state of the art facility positions Alcoa as the world's
        premier aluminum-lithium supplier, offering the broadest portfolio of
        aluminum. Lithium components for next generation aircraft"
        <br />
        <br />
        Alcoa Opens World's Largest Aluminum-Lithium Aerospace Plant in Indiana:
        <br />
      </div>
      <a
        href="https://www.alcoa.com/global/en/news/news_detail.asp?pageID=20141002000232en&amp;newsYear=2014"
        target="_blank"
      >
        Read More
      </a>
      <div className={styles.heading}>
        Cell phones, Computers, Power Tools, iPods and iPads.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img className={styles.img} src={lithium17} alt="lithium" />
      </div>
      <div className={styles.description}>
        It is estimated that in 2014 smartphones users, worldwide, will total
        1.75 billion. In this same time period, Gartner speculates that
        worldwide traditional PC's, tablets, ultra mobile and mobile phone
        shipments are on pace to grow 7.6 percent, an increase of 2.5 billion
        units. Between 2013 and 2017, mobile phone penetration will rise from
        61.1% to 69.4% of the global population, according to a new emerging
        report "Worldwide mobile phone users." On average, mobile devices
        usually carry between 8-25 grams of lithium, depending on the watt hour
        ratings of the device. In contrast, vehicles can carry anywhere from
        0.3Kg (300g) to 5 Kg of lithium, depending on the type of battery and
        the size of the car.
        <br />
        <br />
        Smartphone Users Worldwide Will Total 1.75 Billion in 2014:
        <br />
        <br />
      </div>
      <a
        href="https://www.emarketer.com/Article/Smartphone-Users-Worldwide-Will-Total-175-Billion-2014/1010536"
        target="_blank"
      >
        Read More
      </a>
      <div ref={reserves} className={styles.heading}>
        Global Lithium Reserves
      </div>
      <div className={styles.description}>
        Global estimates suggest there are more than 30 million lithium
        resources, however, it is important to know that most deposits are not
        economically viable. For instance, some of the deposits, both brines and
        hard rock, may have high levels of impurities that make processing very
        costly, while others are in isolated parts of the world and would
        require high infrastructure expenditures, deeming them uneconomical. In
        the case of brines, the weather in some regions is not appropriate for
        the solar evaporation process. There are also many other factors, thus
        it is necessary to spend a significant amount of time and resources to
        determine the feasibility of these projects before considering them as
        viable resources (Euro Pacific- Canada, August 2013)
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "200px",
          }}
          className={styles.img}
          src={lithium18}
          alt="lithium"
        />
      </div>
      <div className={styles.imgSubtitle}>Source USGS</div>
      <div className={styles.description}>
        According to the United States Geological Survey (USGS) it is estimated
        that the global lithium reserves are in the 13 million tons. These
        estimates exclude lithium occurrences and resources that have not proven
        economic.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            height: "200px",
          }}
          className={styles.img}
          src={lithium19}
          alt="lithium"
          
        />
      </div>
      <div className={styles.imgSubtitle}>
        *Global Lithium Reserves from USGS (2011)
      </div>
      <div className={styles.heading}>Brine Deposits Are Not All The Same</div>
      <div className={styles.description}>
        Brine based deposits are located all over the world but only a small
        number of them are considered to be economical for the recovery of
        lithium, potash, boron and potassium. We hear the term "playa" and
        "salar" which primarily means the same thing, a salt encrusted
        depression that may or may not be the basin of an evaporated lake.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img
          style={{
            width: "200px",
          }}
          className={styles.img}
          src={lithium20}
          alt="lithium"
        />
      </div>
      <div className={styles.description}>
        The best explanation of lithium brine based deposit can be found the
        USGS (United Stated Geological Survey):
      </div>
      <br />
      <br />
      <a href="https://pubs.usgs.gov/of/2013/1006/OF13-1006.pdf" target="_blank">
        Read More
      </a>

      <div className={styles.description}>
        Some of the more relevant determining factors for an economical brine
        based Lithium deposit are as follows:
      </div>
      <div className={styles.description}>
        <ul>
          <li>
            Lithium Grate: Solar evaporation of brine can yield a final brine
            with 0.50% to 6% lithium, depending on the initial concentration.
          </li>
          <li>
            Evaporation Rate: This rate depends on the geographical location of
            the deposit. Traditional evaporation cycles can take up to several
            years deeming some locations to be un-economical. Several companies
            are experimenting with various methods of screening systems that
            could process the brine in several days as opposed to periods of 24
            months. These new processing methods are sure to be implemented in
            future mines and we may see conversions to this system in order to
            meet increased demands.
          </li>
          <li>
            By-products to Lithium Brines: Potassium and boron concentrates can
            also be recovered from the brines and processed. The sale of these
            products can make brine operations even more economical.
          </li>
          <li>
            Concentrated Sulphate and Magnesium: The magnesium to lithium ratio
            and lithium to sulphate ration are an important parameter in the
            economic assessment of a brine project. High magnesium levels in the
            brine means that a large amount of lithium may be trapped in the
            magnesium salts during the initial stages of the evaporation process
            reducing recovery rates. Also, a high magnesium to lithium ratio
            means that more soda ash reagent would be required during the
            chemical processing of the brine adding to raw material costs. The
            lower the sulphate to lithium ration in the final lithium brine
            point, the better.
          </li>
          <li>
            Localised Infrastructure: Brine deposits located in remote areas
            away from electricity, water and transportation routes require
            larger initial investment. Another consideration for the operation
            of brine based deposits is the quality of the ponds themselves. They
            must be free of leeks and the location should be able to support
            multiple ponds to allow for each of the salts in the brine to
            crystalize in separate ponds, improving the ultimate recovery of
            lithium.
          </li>
        </ul>
        <br />
        For the sake of this narrative we are excluding the evaporation and
        development of hard rock lithium mining, as it is a historical fact that
        hard rock lithium mining is at least double the cost of brine based
        operations.
      </div>
      <div ref={articles} className={styles.heading}>
        Tesla Motors Inc, the Gigafactory, Elon Musk, and Climate Change
      </div>
      <div className={styles.subHeading}>
        "I could either watch it happen, or be part of it" - Elon Musk
      </div>

      <div className={styles.subHeading}>
        Article by Motley Fool about the Tesla Gigafactory:
      </div>

      <a
        href="https://www.fool.com/investing/general/2014/10/14/what-teslas-gigafactory-means-for-us-manufacturing.aspx"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.subHeading}>
        Panasonic Creates Gigafactory Dedicated Company:
      </div>

      <a
        href="https://cleantechnica.com/2014/10/09/panasonic-gigafactory-company/"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.subHeading}>
        Before the First Tesla Gigafactory Has Even Been Built, CA wants to be
        Home to Tesla's 2nd Gigafactory 2.0:
      </div>

      <a
        href="https://transportevolved.com/2014/09/29/first-tesla-gigafactory-even-built-ca-wants-home-teslas-gigafactory-2-0/"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.subHeading}>
        The sky's the limit for visionary CEO of Tesla:
      </div>

      <a
        href="https://www.sfgate.com/news/article/The-sky-s-the-limit-for-visionary-CEO-of-Tesla-5468907.php"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.subHeading}>
        Elon Musk Tells Stephen Colbert Why He Released Tesla Patents to the EV
        Market:
      </div>

      <a
        href="https://ecowatch.com/2014/07/25/elon-musk-stephen-colbert/"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.subHeading}>
        Since its launch in 1995, TopSpeed.com has been providing news in the
        automotive industry. They currently average 8 million page views per
        month and have the No. 3 ranking on Cision's "2013 Top 50 Auto Blogs"
        list.
        <br />
        Top Speed: The 2015 Tesla Model S:
      </div>

      <a
        href="https://www.topspeed.com/cars/tesla/2015-tesla-model-s-ar165742.html"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.heading}>Related Articles</div>
      <div className={styles.subHeading}>
        The Problem with Fossil Fuels and Climate Change:
      </div>

      <a
        href="https://www.biomassenergycentre.org.uk/portal/page?_pageid=76,539186&amp;_dad=portal&amp;_schema=PORTAL"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.subHeading}>
        The Great Transition, Part I: From Fossil Fuels to Renewable Energy:
      </div>

      <a
        href="https://www.earth-policy.org/plan_b_updates/2012/update107"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.subHeading}>
        Navigant Research
        <br />
        Navigant Research is a market research and consulting team that provides
        in-depth analysis of global clean technology markets. The team's
        research methodology combines supply-side industry analysis, end-user
        primary research and demand assessment, and deep examination of
        technology trends to provide a comprehensive view of these industry
        sectors.
      </div>
      <a
        href="https://www.navigantresearch.com/newsroom/lithium-ion-batteries-for-electric-vehicles-will-surpass-26-billion-in-annual-revenue-by-2023"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.subHeading}>
        Argonne National Laboratory. Affiliated with the US Department of Energy
      </div>
      <div className={styles.description}>
        Argonne National Laboratory located near Lemont, ILoutside Chicago is
        the first science and engineeringresearch national laboratory in the
        United States,receiving this designation on July 1, 1946. Argonne is a
        multidisciplinaryscience and engineering research center, where "dream
        teams" of world-class researchers work alongside experts from industry,
        academia andother government laboratories to address vital national
        challenges in cleanenergy, environment, technology and national
        security. See article on website:
      </div>
      <br />
      <a
        href="https://www.rmi.org/Content/Images/Lithium%20ion.PDF"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.heading}>Doing business in Argentina:</div>
      <div className={styles.subHeading}>
        Sizzling: Argentina's stock market is up 100%
      </div>

      <a
        href="https://money.cnn.com/2014/09/10/investing/argentina-stock-market-gains/"
        target="_blank"
      >
        Read More
      </a>
      <div className={styles.subHeading}>
        Argentina equities still hot despite the country's debt woes - really
      </div>
      <a
        href="https://business.financialpost.com/2014/08/01/argentina-equities-still-hot-despite-the-countrys-debt-woes-really/"
        target="_blank"
      >
        Read More
      </a>

      <div className={styles.heading}>
        Excerpts from a The Business Monitor International article published
        January 1st 2014:
      </div>
      <div className={styles.subHeading}>
        "We point out though that recent indicators suggest the central
        government is seeking in increase foreign investment into the country,
        prodding potential upside to the mining sector in the coming quarters"
      </div>
      <div className={styles.description}>
        "Still, given the country's current mineral output and industry
        capacity, we believe the latter half of the decade is likely to see
        modest growth in the industry value. Argentina's regulatory environment
        is relatively favourable at present. Federal government policies have
        yet to restrict or regulate the mining sector extensively given it's
        previously weak presents. Because mineral rights in Argentina are
        decentralized and regulated by the individual provinces, we expect
        exploration and mine development will centre on provinces that are
        commented towards mining."
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        <img className={styles.img} src={lithium21} alt="lithium" />
      </div>

      <div className={styles.heading}>
        Lithium-Ion Batteries: Possible Material Demand Issues
      </div>

      <div className={styles.subHeading}>
        Argonne National Laboratory Report
      </div>

      <a href="/files/dargonne-report.pdf" target="_blank">
        View Report
      </a>
    </div>
  );
}

Lithium.propTypes = {};

export default withRouter(Lithium);
