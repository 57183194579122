import React from "react";

import styles from "./Management.module.css";

import brian from "../../assets/profiles/brian.png";
import Sameer from "../../assets/profiles/Sameer.jpeg";
import Christopher from "../../assets/profiles/Christopher.jpeg";
import profile5 from "../../assets/profiles/profile-5.png";
import profile2 from "../../assets/profiles/profile-2.png";
import profile4 from "../../assets/profiles/profile-4.jpg";
import profile6 from "../../assets/profiles/profile-6.jpg";
import profile7 from "../../assets/profiles/profile-7.jpg";

const profiles_1 = [
  {
    name: "Brian Findlay",
    designation: "President & Director, HeliosX",
    description:
      "Mr. Findlay brings decades of senior management experience in corporate development, international mining, mergers and acquisitions, exploration and development, mine operations, corporate social responsibility; and managing, financing and administration of public companies. Helped raise more than $200 million in capital for junior resource and technology companies. An expert at managing public companies with international interests.",
  },
  {
    name: "Christopher Brown",
    designation: "CEO & Director, HeliosX",
    description:
      "Mr. Brown has more than 25 years engineering, capital markets and Indigenous partnership experience. Worked as a financial analyst for BMO Capital Markets and Canaccord on international energy and infrastructure opportunities. Has more than 10 years direct energy experience managing budgets in excess of $300 million. Restructured Huntington Exploration Inc. as Director, President & CEO (listed on TSXv (HEI)) into a junior gold exploration company.",
  },
  {
    name: "Sameer Uplenchwar",
    designation: "CFO & Director, HeliosX",
    description:
      "Mr. Uplenchwar has more than 15 years of financial and business development experience. Served as Managing Director of Global Hunter Securities in Calgary, and Head of US Research with GMP Capital in Houston. Was Senior Energy Analyst supporting a $550MM gross long/short strategy fund in New York for Surveyor Capital LLC. Worked for Morgan Stanley in New York, as well as KPMG and Lasalle/ABN AMRO Bank.",
  },
  {
    name: "Frank Busch",
    designation: "Director, HeliosX",
    description:
      "Mr. Busch is a member of Nisichawayasihk (Nee-chise-away-a-see) Cree Nation. Bachelor's degree in Indigenous Studies from the University of Manitoba, 5 certificates from the Canadian Securities Institute and Post-Graduate Certificate in Finance from Harvard University. CEO of NationFUND, and in addition to HeliosX, also on the Board of Huntington Exploration Inc. and Kelso Technologies Inc.; a publicly traded, cross-border, industrial technology company, and as such, is a registered 'insider' on the Toronto and New York Stock Exchanges.",
  },
  {
    name: "Bob Verhelst",
    designation: "Director, HeliosX",
    description:
      "Mr. Verhelst has more than 20 years of senior management experience, including 11 as Partner, Director and Officer of several Western Canada brokerage firms. During this time, he was also President and CEO of a US (FINRA) regulated foreign broker dealer. He has senior risk management experience at CIBC and 11 years combined experience in enforcement for the RCMP, Alberta Securities Commission and Vancouver Stock Exchange. He also has management and board experience with TSX Venture Exchange companies and brings a strong corporate governance background to the Board.",
  },
];

const profiles_2 = [
  {
    name: "Cosme Beccar-Verela",
    designation: "Director of Dajin Resources S.A.",
    description:
      "Cosme Beccar-Verela was born in New York City and graduated as a lawyer at the University of Buenos Aires in 1989. He practiced as a Foreign Associate in Winthrop, Stimson, Putnam & Roberts, New York (1990) and in Huygué de Mahenge, Bloxham et Michaud, Paris (1995). He was an associate Professor of Contract Law in the University of Buenos Aires from his graduation until 2004. Cosme represented Placer Dome while this company explored in Argentina; signed contracts on behalf of Placer with the provincial authorities of Catamarca (Cerro Atajo) and Neuquén (Andacollo). He has represented Continental Illinois Bank and was member of the Committee of banking lawyers as well as a member of the Board of American Express Argentina and of ABB Oil & Gas. His legal practice also includes insurance, trading and industrial companies. Cosme is fluent in English, French and Portuguese and has basic knowledge of Dutch. DICK BENOIT, MSC",
  },
];

const profiles_3 = [
  {
    name: "Dick Benoit",
    description: "Dick Benoit is President of Sustainable Solutions, a Nevada consulting company focused on geothermal resources and drilling. Sustainable Solution’s clients include a wide variety of private companies and governmental agencies, both domestically, and internationally. Completed and ongoing projects have included evaluations of prospect areas, exploration for geothermal resources using all available geoscience techniques, as well as drill program development and supervision. These projects have been located in California, Nevada, Utah, Alaska, Ethiopia, Italy, Iceland, Nicaragua, El Salvador, Mexico, Peru, and Chile."
  },
  {
    name: "Roberto Page",
    description: "Roberto Page graduated as a geologist from Buenos Aires University and has worked since graduation at Argentina’s Geological Survey until his recent retirement in May 2016. Roberto reached the highest management position at the Survey including time directing the Institute of Geology and Mineral Resources (within the Geological Survey). He also had a term as President of the Survey from March 2000-May 2002 during which time he was involved in the promotion of mining investment in Argentina. In 1998, Roberto was appointed Secretary General of the Iberoamerican Geological and Mining Surveys Association and kept this position until 2002, he also served as the Executive Secretary of the Multinational Andean Project (MAP), a five year cooperative effort between Canada and the Geological Surveys of Bolivia, Perú, Chile, Argentina and Canada, that promoted mining exploration in the border areas of those countries. He then went on to be the Technical Coordinator of MAP’s second five year project that also included the Geological Surveys of Venezuela, Colombia, and Ecuador as well as the original four countries. Between 2005 and 2009, Roberto was the President of the Argentine Geological Association and the Argentine International Union of the Geological Sciences National Committee (IUGS). In that role he headed the National Committee for the 2008-2010 Year of the Planet Earth Initiative in Argentina."
  },
];

function Management(props) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Management & Directors</div>
      {profiles_1.map((profile) => {
        return (
          <div className={styles.profiles}>
            {profile.img && <div className={styles.img}>
              <img
                className={styles.imgClass}
                src={profile.img}
                alt="profile"
              />
            </div>}
            <div className={styles.profileDetails}>
              <div className={styles.name}>{profile.name}</div>
              <div className={styles.designation}>{profile.designation}</div>
              <div className={styles.description}>{profile.description}</div>
            </div>
          </div>
        );
      })}

      <div className={styles.heading}>Dajin Resources S.A.</div>
      {profiles_2.map((profile) => {
        return (
          <div className={styles.profiles}>
            {profile.img && <div className={styles.img}>
              <img
                className={styles.imgClass}
                src={profile.img}
                alt="profile"
              />
            </div>}
            <div className={styles.profileDetails}>
              <div className={styles.name}>{profile.name}</div>
              <div className={styles.designation}>{profile.designation}</div>
              <div className={styles.description}>{profile.description}</div>
            </div>
          </div>
        );
      })}

      <div className={styles.heading}>Technical Advisory Board</div>
      {profiles_3.map((profile) => {
        return (
          <div className={styles.profiles}>
            {profile.img && <div className={styles.img}>
              <img
                className={styles.imgClass}
                src={profile.img}
                alt="profile"
              />
            </div>}
            <div className={styles.profileDetails}>
              <div className={styles.name}>{profile.name}</div>
              {profile.designation && <div className={styles.designation}>{profile.designation}</div>}
              <div className={styles.description}>{profile.description}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

Management.propTypes = {};

export default Management;
