const LOAD_LOGIN_SUCCESS = "dajin/app/LOAD_LOGIN_SUCCESS";

const initialState = {
  loaded: false,
  loading: false,
  token: ""
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.data.token,
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
}

export function setLoginDetails(data) {
  return {
    type: LOAD_LOGIN_SUCCESS,
    payload: data,
  };
}

