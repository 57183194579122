import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import config from "../data/config.json";

import {
  Home,
  Management,
  Responsibility,
  Investors,
  Market,
  Reports,
  Financials,
  Projects,
  TeelsMarsh,
  AlkaliLake,
  SalinasGrandes,
  Lithium,
  VideoGallery,
  Technology,
  Revolution,
  Contact,
  News,
  Articles,
  ArchivedNews,
  ArchivedArticles,
  Login,
  Dashboard,
} from "../components";
import { ContentLayout } from "../components/shared";

import aboutUs from "../assets/images/about-us.jpg";
import investors from "../assets/images/investors.jpg";
import projects from "../assets/projects/projects.jpg";
import lithium from "../assets/images/lithium.jpg";
import news from "../assets/images/news.jpg";
import contact from "../assets/images/contact.jpg";

import routes from "./routesPath";
import Layout from "../components/Layout/Layout";

import styles from "./index.module.css";

const Routing = () => {
  const aboutUsImgDetails = {
    imgPath: aboutUs,
    imgAlt: "about-us",
  };

  const investorsImgDetails = {
    imgPath: investors,
    imgAlt: "investors",
  };

  const projectsImgDetails = {
    imgPath: projects,
    imgAlt: "projects",
  };

  const lithiumImgDetails = {
    imgPath: lithium,
    imgAlt: "lithium",
  };

  const newsImgDetails = {
    imgPath: news,
    imgAlt: "news",
  };

  const contactImgDetails = {
    imgPath: contact,
    imgAlt: "news",
  };
  return (
    <Router>
      <Layout>
        <Route path={routes.home} exact component={Home} />
        <Route path={routes.aboutUs}>
          <ContentLayout
            imgDetails={aboutUsImgDetails}
            label="About Us"
            type={config.pageType.about}
            classes={{
              img: styles.img,
            }}
          >
            <Switch>
              <Route path={routes.about} component={Management} />
              <Route path={routes.responsibility} component={Responsibility} />
            </Switch>
          </ContentLayout>
        </Route>
        <Route path={routes.investors}>
          <ContentLayout
            imgDetails={investorsImgDetails}
            label="Investors"
            type={config.pageType.investors}
            classes={{
              img: styles.img,
            }}
          >
            <Switch>
              <Route path={routes.investorsInfo} component={Investors} />
              <Route path={routes.market} component={Market} />
              <Route path={routes.reports} component={Reports} />
              <Route path={routes.financials} component={Financials} />
            </Switch>
          </ContentLayout>
        </Route>

        <Route path={routes.projects}>
          <ContentLayout
            imgDetails={projectsImgDetails}
            label="Projects"
            type={config.pageType.projects}
            classes={{
              img: styles.img,
            }}
          >
            <Switch>
              <Route exact path={routes.projects} component={Projects} />
              <Route path={routes.teelsMarsh} component={TeelsMarsh} />
              <Route path={routes.alkaliLake} component={AlkaliLake} />
              <Route path={routes.salinasGrandes} component={SalinasGrandes} />
            </Switch>
          </ContentLayout>
        </Route>

        <Route path={routes.lithium}>
          <ContentLayout
            imgDetails={lithiumImgDetails}
            label="Lithium"
            type={config.pageType.lithium}
            classes={{
              img: styles.img,
            }}
          >
            <Switch>
              <Route exact path={routes.lithium} component={Lithium} />
              <Route path={routes.lithiumMarket} component={Lithium} />
              <Route path={routes.supplyDemand} component={Lithium} />
              <Route path={routes.products} component={Lithium} />
              <Route path={routes.articles} component={Lithium} />
              <Route path={routes.reserves} component={Lithium} />
              <Route path={routes.gallery} component={VideoGallery} />
              <Route path={routes.technology} component={Technology} />
              <Route path={routes.revolution} component={Revolution} />
            </Switch>
          </ContentLayout>
        </Route>
        <Route path={routes.contact}>
          <ContentLayout
            imgDetails={contactImgDetails}
            label="Contact"
            type={config.pageType.contact}
            classes={{
              img: styles.img,
            }}
          >
            <Switch>
              <Route exact path={routes.contact} component={Contact} />
            </Switch>
          </ContentLayout>
        </Route>

        <Route path={routes.news}>
          <ContentLayout
            imgDetails={newsImgDetails}
            label="News"
            type={config.pageType.news}
            classes={{
              img: styles.img,
            }}
          >
            <Switch>
              <Route path={routes.releases} component={News} />
              <Route path={routes.newsArticles} component={Articles} />
              <Route path={routes.archivedReleases} component={ArchivedNews} />
              <Route path={routes.archivedNewsArticles} component={ArchivedArticles} />
              <Redirect from={routes.news} to={routes.releases} />
            </Switch>
          </ContentLayout>
        </Route>
        <Route exact path={routes.login} component={Login} />

        <Route exact path={routes.dashboard} component={Dashboard} />
      </Layout>
    </Router>
  );
};

export default Routing;
