import React, { memo, Fragment } from "react";
import { Link } from "react-router-dom";

import lithiumField from "../../assets/home/lithium-field.jpg";
import projects from "../../assets/home/our-projects.jpg";
import investorPresentation from "../../assets/images/investor-presentation.jpg";
import investors from "../../assets/home/investors.jpg";
import lithium from "../../assets/home/lithium.jpg";
import styles from "./About.module.css";

function About() {
  return (
    <Fragment>
      <div className={styles.aboutContainer}>
        <div className={styles.heading}>HeliosX</div>
        <div className={styles.subHeading}>
        A Lithium Brine (Battery Metal) Exploration Company.
        </div>
        <div className={styles.companyDetails}>
          <div className={styles.article}>
            <p>
              <span className={styles.brmedium}> Lithium is considered a strategic and "Critical Mineral" due to projected demand for clean energy by the automobile, electronics and energy storage industries. HeliosX is well positioned at Nevada's "Lithium Hub", Argentina's "Lithium Triangle" and Canada's prospective Lithium brine occurrences.</span>
            <img className={styles.img} src={projects} alt="field" />
            <br />
              <span className={styles.brmedium}> Battery grade Lithium carbonate and Lithium hydroxide are in such high demand that global supply will be maxed out by 2025 unless production capacity increases significantly. This has driven industry to explore the potential of developing lower cost Lithium brine extraction processes.</span>
            <br />
              <span className={styles.brmedium}>HeliosX has conducted extensive analysis of Direct Lithium Extraction "DLE" technologies in order to identify the most cost effective and environmentally responsible processes. Through partnerships with Litica Resources S.A. (a subsidiary of Pluspetrol Resources Corporation B.V.), Litus Energy & Environmental Solutions and Lilac Solutions Inc.; and ongoing work with Koch Separation Solutions, we are conducting prefeasibility studies to determine the best technology application for each of our unique reservoirs.</span>
            <br />
            <span className={styles.brmedium}>The benefits of deploying reservoir specific DLE technologies are: the elimination of tailings ponds and attendant land disturbance, much lower water consumption, reducing the extraction process from years to days and significantly lowering operational costs.</span>
            </p>
          </div>
        </div>
      </div>
      <div className={styles.imgContainer}>
        <a
          className={styles.link}
          href={`${process.env.REACT_APP_BACKEND_URL}/files/investors/21HeliosX Presentation-March 2022.pdf`}
        >
          <div className={styles.widget}>
            <img
              className={styles.imageWidget}
              src={investorPresentation}
              alt="presentation"
            />
            <div className={styles.imgLabel}>Investor Presentation</div>
          </div>
        </a>
        <Link className={styles.link} to="/investors/info">
          <div className={styles.widget}>
            <img
              className={styles.imageWidget}
              src={investors}
              alt="investors"
            />
            <div className={styles.imgLabel}>Investors</div>
          </div>
        </Link>
        <Link className={styles.link} to="/projects">
          <div className={styles.widget}>
            <img
              className={styles.imageWidget}
              src={lithiumField}
              alt="our projects"
            />
            <div className={styles.imgLabel}>Projects</div>
          </div>
        </Link>
        <Link className={styles.link} to="/lithium">
          <div className={styles.widget}>
            <img className={styles.imageWidget} src={lithium} alt="lithium" />
            <div className={styles.imgLabel}>Lithium</div>
          </div>
        </Link>
      </div>
    </Fragment>
  );
}

export default memo(About);
