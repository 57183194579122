import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import cx from 'classnames';

import { isEmpty, get } from "lodash-es";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";

import navigation from "../../data/navigation";
import styles from "./ContentLayout.module.css";

function ContentLayout(props) {
  const { children, imgDetails, label, type, classes, location } = props;
  const { imgPath, imgAlt } = imgDetails;

  const navigate = navigation.data.find((item) => item.key === type);
  return (
    <div>
      <img className={classes.img} src={imgPath} alt={imgAlt} />
      <div className={styles.container}>
        <div className={styles.LHS}>
          <div className={styles.type}>{label}</div>
          <div className={styles.navigationContainer}>
            {!isEmpty(navigation) && !isEmpty(get(navigate, 'nesting')) &&
              navigate.nesting.map((item) => {
                const activeTab = location.pathname.includes(item.key);
                return (
                  <Link to={item.link} className={cx(styles.label, {[styles.selected]: activeTab})} key={item.key}>
                    {activeTab ? (
                      <FaCheckCircle className={styles.icon} />
                    ) : (
                      <FaRegCheckCircle className={styles.icon} />
                    )}
                    {item.label}
                  </Link>
                );
              })}
          </div>
        </div>
        <div className={styles.RHS}>
          <div className={styles.divider} />
          {children}
        </div>
      </div>
    </div>
  );
}

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(ContentLayout);
