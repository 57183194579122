import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty, get } from "lodash-es";
import cx from "classnames";

import {
  getNews,
  getArticles,
  getReports,
  getFinancials,
  getInvestors,
} from "../../api/index";
import { setNews, setArticles } from "../../Redux/Reducers/news";
import {
  setReports,
  setFinancials,
  setInvestors,
} from "../../Redux/Reducers/investors";

import Generic from "./Generic";

import styles from "./Dashboard.module.css";
export const Dashboard = (props) => {
  const {
    news,
    setNews,
    setArticles,
    token: reduxToken,
    investors,
    setReports,
    setFinancials,
    setInvestors,
  } = props;
  const { articles } = news;
  const { reports, financials, data } = investors;
  const [component, setComponent] = useState(null);
  const [fData, setFData] = useState(financials);
  let isAuthenticated = false;
  let sessionToken = window.sessionStorage.getItem("token");
  let token = null;

  if (reduxToken || sessionToken) {
    isAuthenticated = true;
    token = reduxToken || sessionToken;
  }

  useEffect(() => {
    if (isEmpty(news.news)) {
      getNews().then((res) => {
        if (res.status === 200) {
          setNews(res.data.payload);
        }
      });
    }

    if (isEmpty(articles)) {
      getArticles().then((res) => {
        if (res.status === 200) {
          setArticles(res.data.payload);
        }
      });
    }

    if (isEmpty(reports)) {
      getReports().then((res) => {
        if (res.status === 200) {
          setReports(res.data.payload);
        }
      });
    }

    if (isEmpty(financials)) {
      getFinancials().then((res) => {
        if (res.status === 200) {
          setFinancials(res.data);
          let data = fDataHandler(res.data);
          setFData(data);
        }
      });
    }

    if (isEmpty(data)) {
      getInvestors().then((res) => {
        if (res.status === 200) {
          setInvestors(res.data);
        }
      });
    }
  }, [component]);

  const fetchNews = () => {
    getNews().then((res) => {
      if (res.status === 200) {
        setNews(res.data.payload);
      }
    });
  };

  const fetchArticles = () => {
    getArticles().then((res) => {
      if (res.status === 200) {
        setArticles(res.data.payload);
      }
    });
  };

  const fetchReports = () => {
    getReports().then((res) => {
      if (res.status === 200) {
        setReports(res.data.payload);
      }
    });
  };

  const fetchFinancials = () => {
    getFinancials().then((res) => {
      if (res.status === 200) {
        setFinancials(res.data);
        setComponent("financials");
        let data = fDataHandler(res.data);
        setFData(data);
      }
    });
  };

  const fetchInvestors = () => {
    getInvestors().then((res) => {
      if (res.status === 200) {
        setInvestors(res.data);
      }
    });
  };

  const handleRenderContent = (component) => {
    setComponent(component);
  };

  const fDataHandler = (data) => {
    let newFinancialData = [];
    let keys = Object.keys(data) || [];
    if(!isEmpty(keys) && keys[0].length === 4) {
      for (let i = 0; i < keys.length; i++) {
        (data[keys[i]].data || []).forEach((item) => {
          newFinancialData.push(item);
        });
      }
      return newFinancialData;
    }
    return data;
  };

  const renderContent = () => {
    switch (component) {
      case "news":
        return (
          <Generic
            type="news"
            heading="News"
            data={get(news, "news", [])}
            token={token}
            callBack={fetchNews}
          />
        );
      case "articles":
        return (
          <Generic
            type="articles"
            heading="Articles"
            data={get(news, "articles", [])}
            token={token}
            callBack={fetchArticles}
          />
        );
      case "reports":
        return (
          <Generic
            type="reports"
            heading="Technical Reports"
            data={get(investors, "reports", [])}
            token={token}
            callBack={fetchReports}
          />
        );
      case "financials":
        return (
          <Generic
            type="financials"
            heading="Financials"
            data={fDataHandler(fData || financials)}
            token={token}
            callBack={fetchFinancials}
          />
        );
      case "investors":
        return (
          <Generic
            type="investors"
            heading="Investors"
            data={get(investors, "data.payload", []) || []}
            token={token}
            callBack={fetchInvestors}
          />
        );
      default:
        return null;
    }
  };

  if (!isAuthenticated) {
    return <div className={styles.container}>You are not Authorized</div>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Please select the section you want to update!
      </div>
      <div
        className={cx(styles.heading, styles.clickableElement)}
        onClick={() => handleRenderContent("news")}
      >
        News
      </div>
      <div
        className={cx(styles.heading, styles.clickableElement)}
        onClick={() => handleRenderContent("articles")}
      >
        Articles
      </div>

      <div
        className={cx(styles.heading, styles.clickableElement)}
        onClick={() => handleRenderContent("reports")}
      >
        Technical Reports
      </div>

      <div
        className={cx(styles.heading, styles.clickableElement)}
        onClick={() => handleRenderContent("financials")}
      >
        Financials
      </div>
      <div
        className={cx(styles.heading, styles.clickableElement)}
        onClick={() => handleRenderContent("investors")}
      >
        Investors
      </div>
      <br />
      {renderContent()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  news: state.news,
  token: state.login.token,
  investors: state.investors,
});

const mapDispatchToProps = {
  setNews,
  setArticles,
  setReports,
  setFinancials,
  setInvestors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
