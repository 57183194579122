import React, { memo, useEffect } from "react";
// import PropTypes from 'prop-types';
import isMobileDevice from "../../utils/isMobile";

import logo from "../../assets/images/banner.jpg";
import logoMobile from "../../assets/images/banner_mobile.jpg";
import video from '../../assets/video/home_video_compressed.mp4'
// import videoMobile from '../../assets/video/home_video_mobile.mp4';
import styles from "./Video.module.css";

function Video() {
  const isMobile = isMobileDevice();
  const homeVideo = isMobile ? video : video;
  const videoBanner = isMobile ? logoMobile : logo;
  useEffect(() => {
    const rVideo = document.getElementById("heliosVideo");
    if (rVideo) {
      rVideo.pause();
      rVideo
        .play()
        .catch((err) => {
          console.log("error playing", err);
        });
    }
  }, [])
  return (
    <div className={styles.videoContainer}>
      <div className={styles.video}>
        <video
          poster={videoBanner}
          id="heliosVideo"
          autoPlay
          loop
          muted
          playslinline
        >
          <source src={homeVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default memo(Video);
