import React, { Fragment, useEffect } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from 'lodash-es';

import { getFinancials } from "../../api/index";
import { setFinancials } from "../../Redux/Reducers/investors";

import { FaRegFilePdf } from "react-icons/fa";

import styles from "./Financials.module.css";

function Financials(props) {
  const { financials, setFinancials } = props;
  useEffect(() => {
    if (isEmpty(financials)) {
      getFinancials().then((res) => {
        if (res.status === 200) {
          setFinancials(res.data);
        }
      });
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Financials</div>
      {Object.keys(financials)
        .sort((a, b) => b - a)
        .map((financial) => {
          return (
            <Fragment>
              <div className={styles.heading}>{financial}</div>
              {financials[financial].data.map((item) => {
                return (
                  <div className={styles.pdfContainer}>
                    <div className={styles.label}>{item.label}</div>
                    {item.fileUrl &&<a
                      className={styles.link}
                      target="_blank"
                      rel="noreferrer"
                      href={`${process.env.REACT_APP_BACKEND_URL}/${(item.fileUrl || '').slice(1)}`}
                    >
                      <FaRegFilePdf size={25} /> Financials
                    </a>}
                    {item.mdaUrl && <a
                      className={styles.link}
                      target="_blank"
                      rel="noreferrer"
                      href={`${process.env.REACT_APP_BACKEND_URL}/${(item.mdaUrl || '').slice(1)}`}
                    >
                      <FaRegFilePdf size={25} /> MD&A
                    </a>}
                  </div>
                );
              })}
            </Fragment>
          );
        })}
    </div>
  );
}

Financials.propTypes = {};

const mapStateToProps = (state) => ({
  financials: state.investors.financials,
});

const mapDispatchToProps = {
  setFinancials,
};

export default connect(mapStateToProps, mapDispatchToProps)(Financials);
