const LOAD_NEWS_SUCCESS = "dajin/app/LOAD_NEWS_SUCCESS";
const LOAD_ARTICLES_SUCCESS = "dajin/app/LOAD_ARTICLES_SUCCESS";
const LOAD_ARCHIVED_NEWS_SUCCESS = "dajin/app/LOAD_ARCHIVED_NEWS_SUCCESS";
const LOAD_ARCHIVED_ARTICLES_SUCCESS = "dajin/app/LOAD_ARCHIVED_ARTICLES_SUCCESS";


const initialState = {
  loaded: false,
  loading: false,
  news: [],
  articles: [],
  archivedArticles: [],
  archivedNews: []
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_NEWS_SUCCESS:
      return {
        ...state,
        news: payload,
        loading: false,
        loaded: true,
      };
    case LOAD_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: payload,
        loading: false,
        loaded: true,
      };
    case LOAD_ARCHIVED_ARTICLES_SUCCESS:
      return {
        ...state,
        archivedArticles: payload,
        loading: false,
        loaded: true,
      };
    case LOAD_ARCHIVED_NEWS_SUCCESS:
      return {
        ...state,
        archivedNews: payload,
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
}

export function setNews(data) {
  return {
    type: LOAD_NEWS_SUCCESS,
    payload: data,
  };
}

export function setArticles(data) {
  return {
    type: LOAD_ARTICLES_SUCCESS,
    payload: data,
  };
}

export function setArchivedArticles(data) {
  return {
    type: LOAD_ARCHIVED_ARTICLES_SUCCESS,
    payload: data,
  };
}

export function setArchivedNews(data) {
  return {
    type: LOAD_ARCHIVED_NEWS_SUCCESS,
    payload: data,
  };
}
