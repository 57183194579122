const LOAD_HEADER_SUCCESS = "dajin/app/LOAD_HEADER_SUCCESS";
const LOAD_TSX_SUCCESS = "dajin/app/LOAD_TSX_SUCCESS";

const initialState = {
  loaded: false,
  loading: false,
  data: {},
  tsx: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_HEADER_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
        loaded: true,
      };
    case LOAD_TSX_SUCCESS:
      return {
        ...state,
        tsx: payload,
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
}

export function setHeaderData(data) {
  return {
    type: LOAD_HEADER_SUCCESS,
    payload: data,
  };
}

export function setTsxData(data) {
  return {
    type: LOAD_TSX_SUCCESS,
    payload: data,
  };
}
