import { combineReducers } from "../../node_modules/redux";
import news from "./Reducers/news";
import investors from "./Reducers/investors";
import header from "./Reducers/header";
import login from "./Reducers/login";
export default combineReducers({
  news,
  investors,
  header,
  login
});