import React, { useEffect } from "react";
// import PropTypes from 'prop-types'
import { FaRegFilePdf } from "react-icons/fa";
import { connect } from "react-redux";
import { isEmpty } from 'lodash-es';

import { getReports } from "../../api/index";
import { setReports } from "../../Redux/Reducers/investors";

import styles from "./Reports.module.css";

function Reports(props) {
  const { reports, setReports } = props;

  useEffect(() => {
    if (isEmpty(reports)) {
      getReports().then((res) => {
        if (res.status === 200) {
          setReports(res.data.payload);
        }
      });
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Technical Reports</div>
      {!isEmpty(reports) && reports.map((info) => {
        return (
          <div className={styles.pdfContainer}>
            <div className={styles.label}>{info.heading}</div>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_BACKEND_URL}/${(info.fileUrl || '').slice(1)}`}
            >
              <FaRegFilePdf size={25} /> PDF
            </a>
          </div>
        );
      })}
    </div>
  );
}

Reports.propTypes = {};

const mapStateToProps = (state) => ({
  reports: state.investors.reports,
});

const mapDispatchToProps = {
  setReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
