const LOAD_INVESTORS_SUCCESS = "dajin/app/LOAD_INVESTORS_SUCCESS";
const LOAD_REPORTS_SUCCESS = "dajin/app/LOAD_REPORTS_SUCCESS";
const LOAD_FINANCIALS_SUCCESS = "dajin/app/LOAD_FINANCIALS_SUCCESS";

const initialState = {
  loaded: false,
  loading: false,
  data: [], // investors data
  reports: [],
  financials: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_INVESTORS_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
        loaded: true,
      };
    case LOAD_REPORTS_SUCCESS:
      return {
        ...state,
        reports: payload,
        loading: false,
        loaded: true,
      };
    case LOAD_FINANCIALS_SUCCESS:
      return {
        ...state,
        financials: payload,
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
}

export function setInvestors(data) {
  return {
    type: LOAD_INVESTORS_SUCCESS,
    payload: data,
  };
}

export function setReports(data) {
  return {
    type: LOAD_REPORTS_SUCCESS,
    payload: data,
  };
}

export function setFinancials(data) {
  return {
    type: LOAD_FINANCIALS_SUCCESS,
    payload: data,
  };
}
