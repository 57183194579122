import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";
import { withRouter } from "react-router";

import { loginDetails } from "../../api/index";

import { setLoginDetails } from "../../Redux/Reducers/login";

import styles from "./Login.module.css";

function Login(props) {
  const { setLoginDetails, history } = props;
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(()=>{
    let sessionToken = window.sessionStorage.getItem("token");
    if(sessionToken) {
      history.push('/dashboard');
    }
  }, [])

  const handleUserName = (e) => {
    setUsername(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    const data = {
      username,
      password,
    };
    loginDetails(data)
      .then((res) => {
        if (res.status === 200) {
          setLoginDetails(res);
          cogoToast.success("Logged In Successfully!!");
          window.sessionStorage.setItem("token", res.data.token);
          history.push('/dashboard');
        }
      })
      .catch((err) => {
        cogoToast.error("Login Failed, please Try Again!!");
      });
  };
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Login</div>
      UserName: <input type="text" onChange={handleUserName} value={username} />
      <br />
      <br />
      Password:{" "}
      <input type="password" onChange={handlePassword} value={password} />
      <br />
      <br />
      <button onSubmit={handleSubmit} onClick={handleSubmit}>
        Login
      </button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  header: state.header,
});

const mapDispatchToProps = {
  setLoginDetails,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
